// https://raw.githubusercontent.com/apollographql/apollo-client/master/src/utilities/policies/pagination.ts
// https://github.com/apollographql/apollo-client/issues/6792#issuecomment-672101025
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#handling-pagination

import {
  lensProp,
  head,
  last,
  propOr,
  view,
} from 'ramda';

// Returns any unrecognized properties of the given object.
const getExtras = ({ edges, wrappers, pageInfo, ...rest }) => rest;

const makeEmptyData = () => {
  return {
    wrappers: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  };
};

// As proof of the flexibility of field policies, this function generates
// one that handles Relay-style pagination, without Apollo Client knowing
// anything about connections, edges, cursors, or pageInfo objects.
export default (keyArgs = false, { inputLens = lensProp('input') } = {}) => {
  return {
    keyArgs,

    read(existing, { args, canRead, readField }) {
      args = view(inputLens, args);

      if (!existing) return;

      const edges = [];
      let startCursor = '';
      let endCursor = '';
      existing.wrappers.forEach(wrapper => {
        // Edges themselves could be Reference objects, so it's important
        // to use readField to access the wrapper.edge.node property.
        if (canRead(readField('node', wrapper.edge))) {
          edges.push(wrapper.edge);
          if (wrapper.cursor) {
            startCursor = (startCursor || wrapper.cursor);
            endCursor = wrapper.cursor;
          }
        }
      });

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          startCursor,
          endCursor,
        },
      };
    },

    // eslint-disable-next-line complexity, max-statements
    merge(existing = makeEmptyData(), incoming, { args, readField }) {
      args = view(inputLens, args);

      // Convert incoming.edges to an array of TEdgeWrapper objects, so
      // that we can merge the incoming wrappers into existing.wrappers.
      const incomingWrappers = incoming.edges
        ? incoming.edges.map(edge => ({
          edge,
          // In case edge is a Reference, we lift out its cursor field and
          // store it in the TEdgeWrapper object.
          cursor: readField('cursor', edge),
        }))
        : [];

      if (incoming.pageInfo) {
        // In case we did not request the cursor field for edges in this
        // query, we can still infer some of those cursors from pageInfo.
        const { startCursor, endCursor } = incoming.pageInfo;

        const firstWrapper = incomingWrappers[0];
        if (firstWrapper && startCursor) firstWrapper.cursor = startCursor;

        const lastWrapper = incomingWrappers[incomingWrappers.length - 1];
        if (lastWrapper && endCursor) lastWrapper.cursor = endCursor;
      }

      let prefix = existing.wrappers;
      let suffix = [];

      if (args && args.after) {
        const index = prefix.findIndex(wrapper => wrapper.cursor === args.after);
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1);
          // suffix = []; // already true
        }
      } else if (args && args.before) {
        const index = prefix.findIndex(wrapper => wrapper.cursor === args.before);
        suffix = index < 0 ? prefix : prefix.slice(index);
        prefix = [];
      } else if (incoming.edges) {
        // If we have neither args.after nor args.before, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = [];
      }

      const wrappers = [...prefix, ...incomingWrappers, ...suffix];

      const firstWrapper = head(wrappers);
      const lastWrapper = last(wrappers);

      const pageInfo = {
        ...incoming.pageInfo,
        ...existing.pageInfo,
        startCursor: propOr('', 'cursor', firstWrapper),
        endCursor: propOr('', 'cursor', lastWrapper),
      };

      if (incoming.pageInfo) {
        const { hasPreviousPage, hasNextPage } = incoming.pageInfo;

        // Keep existing.pageInfo.has{Previous,Next}Page unless the
        // placement of the incoming edges means incoming.hasPreviousPage
        // or incoming.hasNextPage should become the new values for those
        // properties in existing.pageInfo.
        if (!prefix.length && hasPreviousPage !== undefined) {
          pageInfo.hasPreviousPage = hasPreviousPage;
        }
        if (!suffix.length && hasNextPage !== undefined) {
          pageInfo.hasNextPage = hasNextPage;
        }
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        wrappers,
        pageInfo,
      };
    },
  };
};
