export default {
  typography: {
    fontFamily: 'Nike TG, sans-serif',
    h1: {
      fontSize: '2.5rem',
      lineHeight: 2.5,
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 2,
    },
    h3: {
      fontSize: '1.75rem',
      lineHeight: 1.75,
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: 1.5,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: 1.25,
    },
    h6: {
      fontSize: '1rem',
      lineHeight: 1,
    },
    button: {
      fontSize: '1rem',
    },
  },

  overrides: {
    MuiAppBar: {
      root: {
        zIndex: 900,
      },
    },
  },
};
