/* eslint-disable max-statements, react/no-multi-comp */
import React, { memo, useState, useCallback } from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  Button,
  Chip,
  Icon,
  Card,
} from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import { differenceInMinutes, formatDistanceStrict } from 'date-fns/fp';

// aliased
import Link from 'components/Link';
import NestedData from 'components/NestedData';
import Progress from 'components/Progress';
import CopyToClipboard from 'components/CopyToClipboard';
import { propTypes, defaultProps } from 'lib/react';
import { useInterval } from 'lib/hooks/useInterval';
import { useForceUpdate } from 'lib/hooks/useForceUpdate';
import { parseJwt, jwtExpiresAt, jwtIssuedAt } from 'lib/auth';
import { parseUserEmail } from 'lib/string';

// local
import Token from '../Token';


const POLL_INTERVAL = 5; // seconds

const OKTA_AUDIENCE_MAP = {
  'https://nike-qa.oktapreview.com': 'Test',
  'https://nike.okta.com': 'Prod',
};

const usePromiseState = () => {
  const [bool, setBool] = useState(false);
  const runAsyncFunc = useCallback(async func => {
    setBool(true);
    await func();
    setBool(false);
  }, [setBool]);

  return [bool, runAsyncFunc];
};

// eslint-disable-next-line complexity
export default R.applyTo(({ onCopy }) => {

  const [accessTokenTab, setAccessTokenTab] = useState(0);
  const [idTokenTab, setIdTokenTab] = useState(0);
  const [isOktaRefreshing, setOktaRefreshing] = usePromiseState();
  useInterval(useForceUpdate(), POLL_INTERVAL * 1000);

  const { authState, oktaAuth } = useOktaAuth();
  
  const accessToken = R.path(['accessToken', 'accessToken'], authState);
  const accessTokenJson = R.unless(R.isNil, parseJwt)(accessToken);

  const idToken = R.path(['idToken', 'idToken'], authState);
  const oktaUserEmail = R.path(['accessToken', 'claims', 'sub'], authState);

  const oktaExpiresDate = jwtExpiresAt(accessTokenJson) || new Date();
  const oktaIssuedDate = jwtIssuedAt(accessTokenJson) || new Date();

  const oktaAud = R.applyTo(authState, R.pipe(
    R.path(['accessToken', 'claims', 'aud']),
  ));

  const oktaEnv = R.applyTo(oktaAud, R.pipe(
    aud => OKTA_AUDIENCE_MAP[aud] || '?',
  ));

  const handleRefreshOkta = useCallback(() => {
    setOktaRefreshing(async () => {
      const renewToken = await oktaAuth.token.renewTokens();
      await oktaAuth.tokenManager.setTokens(renewToken);
    });
  }, [oktaAuth, setOktaRefreshing]);

  const handleAccessTokenTabChange = useCallback((event, tab) => setAccessTokenTab(tab), [setAccessTokenTab]);
  const handleIdTokenTabChange = useCallback((event, tab) => setIdTokenTab(tab), [setIdTokenTab]);

  const userName = parseUserEmail(oktaUserEmail).join(' ');

  return (
    <Grid container={ true } spacing={ 2 }>

      <Grid item={ true } xs={ 12 }>
        <Card style={{ overflow: 'hidden' }}>
          <Grid container={ true }>
            <Grid item={ true } xs={ 7 }>
              <NestedData
                style={{ fontSize: '18px', width: '100%' }}
                data={{
                  User: {
                    // todo: de-dupe with avatar
                    Name: (
                      <div>
                        { userName }
                        <CopyToClipboard
                          disabled={ false }
                          label={ <Icon>{ 'content_copy' }</Icon> }
                          text={ userName }
                          className={ 'toolbar-button' }
                          title={ 'Copy Name' }
                          onCopy={ onCopy } />
                      </div>
                    ),
                    Email: (
                      <div>
                        <Link to={ `mailto:${ oktaUserEmail }` }>{ oktaUserEmail }</Link>
                        <CopyToClipboard
                          disabled={ false }
                          label={ <Icon>{ 'content_copy' }</Icon> }
                          text={ oktaUserEmail }
                          className={ 'toolbar-button' }
                          title={ 'Copy Email' }
                          onCopy={ onCopy } />
                      </div>
                    ),
                  },
                  TTL: {
                    Issued: !isOktaRefreshing && (
                      <span title={ oktaIssuedDate.toISOString() }>
                        { `${ formatDistanceStrict(oktaIssuedDate, new Date()) } ago` }
                      </span>
                    ),
                    [new Date() > oktaExpiresDate ? 'Expired' : 'Expires In']: (
                      <div
                        style={{
                          color: differenceInMinutes(new Date(), oktaExpiresDate) <= 5 && !isOktaRefreshing
                            ? 'red'
                            : 'inherit',
                        }}>
                        {
                          !isOktaRefreshing && (
                            <span title={ oktaExpiresDate.toISOString() }>
                              { formatDistanceStrict(oktaExpiresDate, new Date()) }
                            </span>
                          )
                        }
                      </div>
                    ),
                  },
                  Client: R.applyTo(authState, R.pipe(
                    R.path(['accessToken', 'claims', 'cid']),
                    cid => <Link to={ `https://console.platforms.nike.com/developer/apps/${ cid }` }>{ cid }</Link>
                  )),
                  Environment: (
                    <span>
                      { `${ oktaEnv } (` }
                      <Link to={ oktaAud }>{ R.replace('https://', '', oktaAud) }</Link>
                      { ')' }
                    </span>
                  ),
                  Groups: R.applyTo(authState, R.pipe(
                    R.pathOr([], ['accessToken', 'claims', 'groups']),
                    R.map(group => (
                      <Chip
                        variant={ 'outlined' }
                        key={ group }
                        label={ group } />
                    )),
                  )),
                }} />
                
            </Grid>
            <Grid
              item={ true }
              xs={ 5 }
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Button
                    onClick={ handleRefreshOkta }
                    variant={ 'outlined' }
                    size={ 'large' }
                    title={ 'Refresh Okta Tokens' }
                    disabled={ isOktaRefreshing }
                    startIcon={ <Icon>{ 'refresh' }</Icon> }
                    style={{ margin: '5px', width: '150px' }}
                  >
                    { isOktaRefreshing ? ' Refreshing...' : ' Refresh' }
                  </Button>
                </div>
                <div>
                  <Link to={ '/logout' }>
                    <Button
                      variant={ 'outlined' }
                      size={ 'large' }
                      title={ 'Logout from Okta' }
                      startIcon={ <Icon>{ 'logout' }</Icon> }
                      style={{ margin: '5px', width: '150px' }}
                    >
                      { ' Logout' }
                    </Button>
                  </Link>
                </div>
              </div>
            </Grid>

          </Grid>
        </Card>
      </Grid>
      

      <Grid item={ true } xs={ 6 }>
        <Card>
          <Typography variant='h4' align={ 'center' } style={{ background: 'rgba(0, 0, 0, 0.1)', padding: '10px' }}>
            <Icon style={{ marginRight: '10px', verticalAlign: 'middle' }}>{ 'lock' }</Icon>
            <span style={{ verticalAlign: 'middle' }}>{ 'Access Token' }</span>
          </Typography>
          {
            isOktaRefreshing
              ? (<Progress />)
              : (
                <Token
                  token={ accessToken }
                  onTabChange={ handleAccessTokenTabChange }
                  tab={ accessTokenTab } />
              )
          }
        </Card>
      </Grid>

      <Grid item={ true } xs={ 6 }>
        <Card>
          <Typography variant='h4' align={ 'center' } style={{ background: 'rgba(0, 0, 0, 0.1)', padding: '10px' }}>
            <Icon style={{ marginRight: '10px', verticalAlign: 'middle' }}>{ 'person' }</Icon>
            <span style={{ verticalAlign: 'middle' }}>{ 'Identity Token' }</span>
          </Typography>
          {
            isOktaRefreshing
              ? (<Progress />)
              : (
                <Token
                  token={ idToken }
                  onTabChange={ handleIdTokenTabChange }
                  tab={ idTokenTab } />
              )
          }
        </Card>
      </Grid>
      
    </Grid>
  );
}, R.pipe(
  propTypes({}),
  defaultProps({}),
  memo
));
