import { cond, equals, isNil } from 'ramda';

export const EMPTY_AUTH_KEY = 'NONE';

// String -> String
export const authHumanName = cond([
  [equals('OKTA'), _ => 'Okta'],
  [equals('ACCOUNTS_NIKE_COM'), _ => 'Nike'],
  [isNil, _ => 'None'],
  [equals(EMPTY_AUTH_KEY), _ => 'None'],
  [_ => true, _ => 'Unknown'],
]);

// Number -> String HexColor
export const reqTimeColor = cond([
  // green
  [ms => ms < 300, _ => '#13ac0e'],
  // orange
  [ms => ms < 1000, _ => '#e98333'],
  // red
  [_ => true, _ => '#f20303'],
]);

// calculate json data size in bytes
// Object -> Int
export const dataSize = data => new TextEncoder().encode(JSON.stringify(data)).length;
export const parseTraceId = header => (header || '').split('=')[1];
export const xrayTraceLink = traceId => `https://console.aws.amazon.com/cloudwatch/home?#servicelens:traces/${ traceId }`;
