// modules
import React, { memo, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe, path } from 'ramda';
import cx from 'classnames';
import { useTheme } from '@material-ui/core/styles';


// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './Code.module.scss';


// display preformatted code
// eslint-disable-next-line max-statements
export default applyTo(({
  children,
  className,
  editable,
  hidden,
  inline,
  onChange,
  ...rest
}) => {
  if (hidden) return null;

  const theme = useTheme();
  const palette = path(['palette', 'type'], theme);

  const classNames = cx(className, styles.code, {
    [styles.inline]: inline,
    [styles[palette]]: true,
  });

  if (typeof children === 'object' && !isValidElement(children)) {
    const spaces = (inline ? 0 : 2);
    children = JSON.stringify(children, null, spaces);
  }

  if (editable) {
    if (typeof children !== 'string') children = 'ERROR: Editable Must Be String';
    return (
      <textarea
        className={ classNames }
        onChange={ onChange }
        value={ children }
        { ...rest }
      >
        { children }
      </textarea>
    );
  }
  
  const usePlaceholder = (!children && rest.placeholder);

  return (
    <pre className={ cx(classNames, { [styles.placeholder]: usePlaceholder }) } { ...rest }>
      { (usePlaceholder ? rest.placeholder : children) }
    </pre>
  );
}, pipe(
  propTypes({
    children: PropTypes.node,
    className: PropTypes.string,
    hidden: PropTypes.bool,
    inline: PropTypes.bool,
    onChange: PropTypes.func,
  }),
  defaultProps({
    className: '',
    editable: false,
    hidden: false,
    inline: true,
    onChange: _ => {},
  }),
  memo,
));
