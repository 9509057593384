// modules
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { pipe, applyTo } from 'ramda';
import { withRouter } from 'react-router-dom';
import { Button, ThemeProvider, Paper } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';

// aliased
import FlexCenter from 'components/FlexCenter';
import { propTypes, defaultProps } from 'lib/react';

import logoWhite from 'assets/logoWhite.png';
import logoBlack from 'assets/logoBlack.png';

// local
import styles from './Login.module.scss';
import light from '../../lib/themes/light';
import dark from '../../lib/themes/dark';

const STORED_THEME = window.localStorage.getItem('theme', 'dark');

export default applyTo(() => {
  const { oktaAuth } = useOktaAuth();

  const handleLogin = useCallback(() => {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  }, [oktaAuth]);

  return (
    <ThemeProvider theme={ STORED_THEME === 'light' ? light : dark }>
      <Paper>
        <FlexCenter className={ styles.wrapper }>
          <div>
            <img src={ STORED_THEME === 'light' ? logoBlack : logoWhite } alt='VL' />
            <Button
              variant='outlined'
              onClick={ handleLogin }>Login with OKTA</Button>
          </div>
        </FlexCenter>
      </Paper>
    </ThemeProvider>
  );
}, pipe(
  propTypes({
    title: PropTypes.string,
  }),
  defaultProps({
    title: 'Login',
  }),
  withRouter,
  memo,
));
