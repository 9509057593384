// modules
import React, { memo, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { pipe, applyTo } from 'ramda';
// aliased
import { propTypes, defaultProps } from 'lib/react';
import { useNikeAuth } from 'components/NikeAuthProvider';
import Progress from 'components/Progress';

export default applyTo(() => {
  const { user } = useNikeAuth();

  useEffect(() => {
    if (user) {
      const href = sessionStorage.getItem('signin_url');
      if (href) {
        window.location.href = href;
        return null;
      }
    }
  }, [user]);

  return <Progress />;
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
