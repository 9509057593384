// modules
import React from 'react';
import PropTypes from 'prop-types';
import fetch from 'unfetch';
import { applyTo, path, pipe } from 'ramda';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/link-error';
import { useOktaAuth } from '@okta/okta-react';

// aliased
import { typeDefs } from 'lib/graphql/type';
import QUERY_KEY_ARGS from 'lib/graphql/key-args';
import relayFieldPolicy from 'lib/graphql/relay-field-policy';
import { propTypes } from 'lib/react';
import { jwtIsExpired } from 'lib/auth';
import config from 'conf';


const httpLink = new HttpLink({
  uri: `${ config.API_HOST }/graphql`,
  fetch,
  // seeing a lot of strange issues that seem to be related to this...
  // useGETForQueries: true,
});

const authMiddleware = authService => new ApolloLink((operation, forward) => {
  const { accessToken } = operation.getContext();

  if (jwtIsExpired(accessToken)) authService.clearAuthState();
  
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${ accessToken }` : null,
    },
  });

  return forward(operation);
});

// @TODO implement cache once we know what the data looks like?
const cache = new InMemoryCache({
  // Include this object to define polymorphic relationships between your schema's types.
  // Doing so enables you to look up cached data by interface or by union.
  // todo: read this from the graph at build-time
  possibleTypes: {
    Node: ['Order', 'Bet'],
    User: ['OktaUser'],

  },
  typePolicies: {
    Query: {
      fields: {
        orderByAppId: relayFieldPolicy(QUERY_KEY_ARGS.orderByAppId),
      },
    },
  },
});

export default applyTo(({ children }) => {
  const { authState, authService, oktaAuth } = useOktaAuth();

  const withAccessToken = setContext(() => (authState.isAuthenticated
    ? path(['accessToken'], authState)
    : null
  ));

  const client = new ApolloClient({
    link: ApolloLink.from([
      withAccessToken,
      authMiddleware(authService),
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${ message }, Location: ${ locations }, Path: ${ path }`);
          });
        }
        const status = path(['response', 'status'], operation.getContext());
        if (status === 401) { // if 401 from API.  log the user out.
          console.error('401 - Unauthorized');
          if (authService) authService.clearAuthState();
          if (oktaAuth) oktaAuth.signOut();
        }
        if (networkError) console.log(`[Network error]: ${ networkError }`);
      }),
      httpLink,
    ]),
    //@TODO replace cache above with correct types
    cache, // : new InMemoryCache(),
    connectToDevTools: true,
    typeDefs,
  });

  return (
    <ApolloProvider client={ client }>
      { children }
    </ApolloProvider>
  );
}, pipe(
  propTypes({
    children: PropTypes.node,
  }),
  React.memo,
));
