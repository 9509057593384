import * as R from 'ramda';
import { capitalize } from '@vl/js-lib/browser/string';

// we don't want to replace spaces so we can't use slugify from workshop-lib
export const slugify = R.pipe(
  R.toLower,
  R.replace(/[^a-z0-9]/g, '-'),
  R.replace(/-+/g, '-'),
);

export const parseUserEmail = R.pipe(
  R.ifElse(R.isNil, _ => [], R.pipe(
    R.split('@'),
    R.head,
    R.replace(/[\d]/g, ''),
    R.split('.'),
    R.map(capitalize),
  )),
);
