import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import OriginalGraphiQL from 'graphiql';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { useToasts } from 'react-toast-notifications';
import { Icon } from '@material-ui/core';
import cx from 'classnames';
import {
  applyTo,
  chain, cond, curry,
  equals, evolve,
  filter,
  head,
  isEmpty, isNil, ifElse,
  path, pathOr, pick, pipe, prop, propEq, propOr,
  unless,
  T,
} from 'ramda';

// lib
import { toHumanJSON } from '@vl/js-lib/browser/object';
import { queryHttp } from '@vl/js-lib/browser/graphql';
import { parse, serialize } from '@vl/js-lib/browser/url/querystring';
import { throwError } from '@vl/js-lib/browser/error';

// aliased
import { useNikeAuth } from 'components/NikeAuthProvider';
import CopyToClipboard from 'components/CopyToClipboard';
import Progress from 'components/Progress';
import Error from 'components/Error';
import Link from 'components/Link';
import useFullscreen from 'lib/hooks/useFullscreen';
import { NamespacedLocalStorage } from 'lib/util';
import { downloadData } from 'lib/download';


// local
import styles from './index.module.scss';
import {
  authHumanName,
  dataSize,
  parseTraceId,
  reqTimeColor,
  xrayTraceLink,
  EMPTY_AUTH_KEY,
} from './lib';

const EMPTY_OPTION_KEY = '__EMPTY__';
// whether or not switching graphs maintains the current query / vars
const PERSIST_QUERY_BETWEEN_GRAPHS = false;

// no need to display empty variables objects
const serializeVars = ifElse(isEmpty, _ => '', toHumanJSON);

// whitelist of known / allowed qs keys
// QueryArgs -> QueryArgs
const pickQs = pick(['query', 'variables', 'host', 'auth']);

// History -> QueryArgs
const readQuery = pipe(path(['location', 'search']), parse, pickQs);

// String -> [Bet] -> GraphqlApi
const findGraph = curry((slug, bets) => {
  return chain(propOr([], 'apis'), bets || [])
    .find(propEq('slug', slug));
});


// eslint-disable-next-line max-statements, complexity
const GraphiQL = ({ graphs, loading }) => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef();
  const [isFullscreen, setIsFullscreen] = useFullscreen(editorRef);
  const { addToast } = useToasts();
  const { authState, authService, oktaAuth } = useOktaAuth();
  const { signIn: nikeSignIn, user: nikeUser, needsSignIn } = useNikeAuth();
  const [lastResMeta, setLastResMeta] = useState(null);
  const { auth, query, variables, host } = readQuery(history);

  // fixme: consider making this just an object
  // String -> String JWT | Null
  const authTokenFor = useCallback(cond([
    [equals('ACCOUNTS_NIKE_COM'), _ => prop('access_token', nikeUser)],
    [equals('OKTA'), _ => path(['accessToken', 'accessToken'], authState)],
    [T, _ => null],
  ]), [nikeUser, authState]);
  
  const currentGraph = findGraph(host, graphs);
  const currentSlug = prop('slug', currentGraph);

  const handleEditQs = useCallback(async qs => {
    const mergedQuery = { ...readQuery(history), ...pickQs(qs) };
    history.replace(`${ location.pathname }?${ serialize(mergedQuery) }`);
  }, [history, location.pathname]);

  const handleToggleFullscreen = useCallback(() => setIsFullscreen(!isFullscreen), [setIsFullscreen, isFullscreen]);
  
  // eslint-disable-next-line max-statements
  const handleEditHost = useCallback(event => {
    const updates = { host: event.target.value };
    const nextGraph = findGraph(updates.host, graphs);

    if (!nextGraph) {
      return addToast(`Unknown Graph "${ updates.host }"`, { appearance: 'error' });
    }

    const nextGraphAuthProviders = propOr([], 'authProviders', nextGraph);
    const nextGraphActiveAuthProviders = filter(authTokenFor, nextGraphAuthProviders);
    
    // can't use the current auth
    // select the most proper default auth for the next graph
    if (auth && (auth !== EMPTY_AUTH_KEY) && !nextGraphAuthProviders.includes(auth)) {
      updates.auth = head(nextGraphActiveAuthProviders);
    }
    // current graph is unauthed, but the next graph supports at least one active auth
    if ((!auth || (auth === EMPTY_AUTH_KEY)) && nextGraphActiveAuthProviders.length) {
      updates.auth = head(nextGraphActiveAuthProviders);
    }
    
    // fixme: not sure if this should use empty values instead
    if (!PERSIST_QUERY_BETWEEN_GRAPHS) {
      const example = path(['examples', 0], nextGraph);
      updates.query = propOr('', 'query', example);
      updates.variables = applyTo(example, pipe(
        propOr('', 'variables'),
        serializeVars,
      ));
    }

    setLastResMeta(null);
    handleEditQs(updates);
  }, [handleEditQs, auth, authTokenFor, graphs]);

  const handleEditAuth = useCallback(event => {
    setLastResMeta(null);
    handleEditQs({ auth: path(['target', 'value'], event) });
  }, [handleEditQs, setLastResMeta]);
  
  const handleEditVariables = useCallback(variables => handleEditQs({ variables }), [handleEditQs]);
  const handleEditQuery = useCallback(query => handleEditQs({ query }), [handleEditQs]);
  const handleDownloadData = useCallback(_ => {
    const fileparts = [prop('slug', currentGraph), 'gql', Date.now()];
    return downloadData(`${ fileparts.join('-') }.json`, {
      data: lastResMeta.data,
      metadata: pick(['duration', 'traceId'], lastResMeta),
    });
  }, [lastResMeta, currentGraph]);

  const handleSelectExample = useCallback(event => {
    const name = path(['target', 'value'], event);
    const example = propOr([], 'examples', currentGraph)
      .find(propEq('name', name));
    if (!example) return;

    applyTo(example, pipe(
      evolve({ variables: serializeVars }),
      handleEditQs,
    ));
  }, [currentGraph, handleEditQs]);

  // default missing query vars on-load
  // fixme: consider splitting this into multiple effects?
  // eslint-disable-next-line
  useEffect(() => {
    // cannot defaul vars without knowledge of the graphs
    if (loading || !graphs) return;
    
    // default missing graph selection
    if (!currentGraph) {
      if (host) {
        addToast(`Invalid Graph: "${ host }"`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      const value = path([0, 'apis', 0, 'slug'], graphs);
      return handleEditHost({ target: { value } });
    }

    // default missing auth selection
    if (!auth) {
      const value = applyTo(currentGraph, pipe(
        propOr([], 'authProviders'),
        filter(authTokenFor),
        propOr(EMPTY_AUTH_KEY, 0),
      ));
      handleEditAuth({ target: { value } });
    }
        
    // // set initial query / vars only if both query & vars are *unset*, not just empty
    // if (!has('query', qs) && !has('variables', qs)) {
    //   defaults = {
    //     ...defaults,
    //     query: '',
    //     variables: '',
    //     ...applyTo(currentGraph, pipe(
    //       pathOr({}, ['examples', 0]),
    //       evolve({ variables: serializeVars }))),
    //   };
    // }

  }, [graphs, currentGraph, loading, auth, authTokenFor, host]);

  // every time the auth provider changes, re-auth
  useEffect(() => {
    applyTo(auth, cond([
      [equals('OKTA'), _ => {
        if (!authState) oktaAuth.signInWithRedirect({ originalUri: location.pathname });
      }],
      [equals('ACCOUNTS_NIKE_COM'), _ => {
        if (needsSignIn) nikeSignIn();
      }],
    ]));
  }, [auth, needsSignIn, authState]);
  
  // GraphQL + HTTP logic lives here
  // eslint-disable-next-line max-statements, complexity
  const graphQLFetcher = useCallback(async (req, opts) => {
    if (!currentGraph) return '';
    // don't fetch if nike auth and not signed in yet
    if (auth === 'ACCOUNTS_NIKE_COM' && needsSignIn) return '';
    // this is "auth is not defined", not "explicitly unauthorized"
    if (!auth) return '';

    const headers = propOr({}, 'headers', opts);

    const isIntrospection = (req.operationName === 'IntrospectionQuery');

    if (!isIntrospection) setLastResMeta(null);
    const start = Date.now();

    const authorization = applyTo(auth, pipe(
      authTokenFor,
      unless(isNil, token => `Bearer ${ token }`),
    ));

    // authenticated but no token?
    // should be impossible, but if it happens, it'll cause an infinite auth redirect
    if (!authorization && auth && auth !== EMPTY_AUTH_KEY) {
      return console.error('Missing auth token', { auth });
    }
    
    const res = await queryHttp({
      url: currentGraph.url,
      headers: { ...headers, authorization },
    }, req).catch(async err => {

      // all non-200s result in an error toast
      addToast(`${ isIntrospection ? 'Introspection' : 'Query'  } Failed`, {
        appearance: 'error',
        autoDismiss: true,
      });
      
      if (err.status !== 401) throw err;

      const couldBeAuthed = !!pathOr(0, ['authProviders', 'length'], currentGraph);

      if (!auth || (auth === EMPTY_AUTH_KEY) && couldBeAuthed) {
        addToast('Unauthorized: Select an auth provider', { appearance: 'warning', autoDismiss: true });
      }

      // refresh auth on failed query
      return applyTo(auth, cond([
        [equals('ACCOUNTS_NIKE_COM'), _ => nikeSignIn()],
        [equals('OKTA'), _ => oktaAuth.signInWithRedirect({ originalUri: location.pathname })],
        [T, _ => throwError(err)],
      ]));
    });

    // persist results for display in ui / download
    if (!isIntrospection) {
      setLastResMeta({
        data: res.data,
        duration: (Date.now() - start),
        traceId: parseTraceId(
          path(['headers', 'x-amzn-trace-id'], res)
        ),
        // note: content-length header doesn't account for gzip
        contentLength: dataSize(prop('data', res)),
      });
    }

    return pick(['data', 'errors'], res || {});
  }, [nikeUser, authService, authState, currentGraph, auth, location.pathname]);

  const handleCopyAccessToken = useCallback(_ => addToast(
    `Copied ${ authHumanName(auth) } Access Token`
    , {
      appearance: 'success',
      autoDismiss: true,
    }), [auth]);

  const handleCopyLink = useCallback(url => addToast('Copied Permalink', {
    appearance: 'success',
    autoDismiss: true,
  }), []);

  if (authState.error) return <Error error={ authState.error } />;

  const toolbar = (
    <>
      <span className={ styles.seperator } />
      <CopyToClipboard
        disabled={ !auth || (auth === EMPTY_AUTH_KEY) }
        label={ <Icon>{ 'key' }</Icon> }
        text={ (authTokenFor(auth) || '') }
        className={ 'toolbar-button' }
        title={
          (auth && auth !== EMPTY_AUTH_KEY)
            ? `Copy ${ authHumanName(auth) } Access Token`
            : 'No Access Token (Unauthenticated)'
        }
        onCopy={ handleCopyAccessToken } />
      <CopyToClipboard
        label={ <Icon>{ 'link' }</Icon> }
        text={ window.location.href }
        className={ 'toolbar-button' }
        title={ 'Copy Link' }
        onCopy={ handleCopyLink } />
      <button title={ `${ isFullscreen ? 'Exit ' : 'Enter ' } Fullscreen` } className={ cx(styles.fullscreen, 'toolbar-button') } onClick={ handleToggleFullscreen }>
        <Icon>{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }</Icon>
      </button>
      <Link disabled={ !prop('traceId', lastResMeta) } to={ xrayTraceLink(prop('traceId', lastResMeta)) }>
        <button
          title={
            applyTo(lastResMeta, cond([
              [meta => !meta, _ => 'No response to XRay trace'],
              [prop('traceId'),  _ => 'View XRay trace'],
              [T, _ => 'No XRay trace found in response'],
            ]))
          }
          disabled={ !prop('traceId', lastResMeta) }
          className={ cx('toolbar-button') }
        >
          <Icon>{ 'search' }</Icon>
        </button>
      </Link>
      <button
        title={ prop('data', lastResMeta) ? 'Download Results' : 'No results to download' }
        disabled={ !prop('data', lastResMeta) }
        className={ 'toolbar-button' }
        onClick={ handleDownloadData }
      >
        <Icon>{ 'download' }</Icon>
      </button>
      <span className={ styles.seperator } />
      <select
        id='api-select'
        disabled={ loading }
        title={ propOr(0, 'length', graphs) ? 'Select a Graph' : 'No Graphs Available' }
        className={ cx(styles.dropdown, 'toolbar-button') }
        onChange={ handleEditHost }
        style={{ fontWeight: 'bold', boxShadow: '0px 0px 2px 2px rgba(25,71,255,0.4)' }}
        value={ host || EMPTY_OPTION_KEY }
      >
        <option disabled={ true } key={ 'NONE' } value={ EMPTY_OPTION_KEY }>
          { 'Select a Graph' }
        </option>
        {
          (graphs || []).map(({ name, active, slug, apis }, i) => (
            <optgroup
              key={ (slug || i) }
              title={ active ? '' : '(Graphs may still be available)' }
              label={ `${ name }${ !active ? ' (Inactive)' : '' }` }
            >
              { apis.map(({ name: apiName, slug: apiSlug }) => (
                <option
                  key={ apiSlug }
                  value={ apiSlug }
                  title={
                    (host === apiSlug)
                      ? `Using ${ name }'s ${ apiName } graph`
                      : `${ (host === apiSlug) ? 'Using' : 'Switch to' } ${ name }'s ${ apiName } graph`
                  }
                >
                  { name } - { apiName }
                </option>
              )) }
            </optgroup>
          ))
        }
      </select>
      <select
        className={ cx(styles.dropdown, 'toolbar-button') }
        onChange={ handleEditAuth }
        style={{ fontWeight: 'bold' }}
        disabled={ !currentGraph }
        title={
          applyTo(null, cond([
            [_ => !currentGraph, _ => 'No graph selected'],
            [_ => !pathOr(0, ['authProviders', 'length'], currentGraph), _ => 'Graph does not support auth'],
            [_ => (auth === EMPTY_AUTH_KEY), _ => 'Not using auth'],
            [_ => auth, _ => `Using ${ authHumanName(auth) } Auth`],
            [_ => true, _ => 'Select auth provider'],
          ]))
        }
        value={ auth }
      >
        <option
          value={ EMPTY_AUTH_KEY }
          title={
            !auth || (auth === EMPTY_AUTH_KEY)
              ? 'Already not using auth'
              : 'Don\'t use auth'
          }>
          { 'No Auth' }
        </option>
        {
          propOr([], 'authProviders', currentGraph).map(option => {
            const human = authHumanName(option);
            const isAuthed = !!authTokenFor(option);

            const title = applyTo(null, cond([
              [_ => isAuthed && (auth === option), _ => `Already using ${ human } auth`],
              [_ => isAuthed && (auth !== option), _ => `Use ${ human } auth`],
              [_ => !isAuthed, _ => `Login and use ${ human } auth`],
            ]));

            return (
              <option key={ option } value={ option } title={ title }>
                { `${ isAuthed ? '✅' : '❌' } ${ human }` }
              </option>
            );
          })
        }
      </select>
      <select
        className={ cx(styles.dropdown, 'toolbar-button') }
        onChange={ handleSelectExample }
        disabled={ !pathOr(0, ['examples', 'length'], currentGraph) }
        title={ pathOr(0, ['examples', 'length'], currentGraph) ? 'Select an example' : 'No examples' }
        value={ EMPTY_OPTION_KEY }
      >
        <option disabled={ true } value={ EMPTY_OPTION_KEY }>
          { 'Examples' }
        </option>
        {
          propOr([], 'examples', currentGraph).map(({ name }) => (
            <option key={ name } value={ name }>
              { name }
            </option>
          ))
        }
      </select>

      <span className={ styles.resMeta } style={{ display: (lastResMeta ? null : 'none') }}>
        <span className={ styles.seperator } />
        <span style={{ whiteSpace: 'nowrap' }}>
          { `${ (propOr(1, 'contentLength', lastResMeta) / 1024).toFixed(2) } kb` }
        </span>
        <span>{ '/' }</span>
        <span
          style={{
            color: reqTimeColor(prop('duration', lastResMeta)),
            whiteSpace: 'nowrap',
          }} >
          { `${ propOr(0, 'duration', lastResMeta) } ms` }
        </span>
      </span>
    </>
  );

  // default empty graph
  if (!currentGraph && !loading) {
    return <Progress>{ 'Selecting Default Graph...' }</Progress>;
  }

  return (
    <div className={ styles.playground } ref={ editorRef }>
      { /* https://github.com/graphql/graphiql/tree/main/packages/graphiql#props */ }
      {
        !loading && (
          <OriginalGraphiQL
            // force re-render when graph or auth changes
            key={ `${ currentSlug }-${ auth }` }
            defaultVariableEditorOpen={ true }
            toolbar={{ additionalContent: toolbar }}
            fetcher={ graphQLFetcher }
            storage={ NamespacedLocalStorage(localStorage, currentSlug) }
            variables={ variables }
            query={ query }
            onEditVariables={ handleEditVariables }
            onEditQuery={ handleEditQuery } />
        )
      }
      {
        prop('url', currentGraph) && (
          <div
            className={ styles.currentUrl }
            title={ 'Current URL' }
          >
            { prop('url', currentGraph) }
          </div>
        )
      }
      { (loading || authState.isPending) && <Progress
        style={{
          position: 'absolute',
          top: 0, left: 0, right: 0, bottom: 0,
          background: 'rgba(255, 255, 255, 0.7)',
          zIndex: '10',
        }}>Loading Graphs...</Progress> }
    </div>
  );
};

GraphiQL.propTypes = {
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      apis: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
          url: PropTypes.string,
          authProviders: PropTypes.arrayOf(
            PropTypes.oneOf(['OKTA', 'ACCOUNTS_NIKE_COM'])
          ),
          examples: PropTypes.arrayOf(
            PropTypes.shape({
              query: PropTypes.string,
              variables: PropTypes.shape({}),
            }),
          ),
        })
      ),
    })
  ),
  loading: PropTypes.bool,
};
GraphiQL.defaultProps = {
  graphs: [],
  loading: false,
};

export default memo(GraphiQL);
