import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';


import ApolloProvider from 'components/ApolloProvider';
import App from 'views/App';
import * as serviceWorker from './serviceWorker';
import { ToastProvider } from 'react-toast-notifications';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Authentication from 'components/Authentication';
import NikeAuthProvider from 'components/NikeAuthProvider';
import './index.scss';

const app = (
  <Router>
    <Authentication>
      <ApolloProvider>
        <StylesProvider injectFirst={ true }>
          <ToastProvider>
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
              <NikeAuthProvider>
                <App />
              </NikeAuthProvider>
            </MuiPickersUtilsProvider>
          </ToastProvider>
        </StylesProvider>
      </ApolloProvider>
    </Authentication>
  </Router>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
