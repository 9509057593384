import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe, hasPath } from 'ramda';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import NotFound from 'components/NotFound';

// local
import Users from './components/Users';
import User from './components/User';

export default applyTo(() => {
  // check if we are on a bet page
  const match = useRouteMatch('/bet/:slug');

  if (hasPath(['params', 'slug'], match)) {
    return (
      <Switch>
        <Route path={ `${ match.path }/user` } exact={ true } component={ Users } />
        <Route path={ `${ match.path }/user/:username` } exact={ true } component={ User } />
        <Route component={ NotFound } />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={ '/user' } exact={ true } component={ Users } />
      <Route path={ '/user/:username' } exact={ true } component={ User } />
      <Route component={ NotFound } />
    </Switch>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
