// modules
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';
// aliased

// local
import styles from './Link.module.scss';

const isLocalAddress = (to) => {
  const startsWithSlash = /^\//.test(to);
  const startsWithProto = /^https?/.test(to);
  const isMailto = /^mailto/.test(to);
  if (isMailto) return false;
  return startsWithSlash || !startsWithProto;
};

export default class Link extends PureComponent {

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    download: PropTypes.string,
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
    openNew: PropTypes.bool,
    style: PropTypes.shape(),
    title: PropTypes.string,
    to: PropTypes.string,
  }
  static defaultProps = {
    // openNew: true,
    disabled: false,
  }

  // eslint-disable-next-line complexity
  wrap = (content) => {
    const { className, disabled, download, onClick, openNew, to, title } = this.props;
    const classes = cx(className, styles.link, {
      [styles.disabled]: disabled,
      [styles.text]: (typeof content === 'string'),
      [styles.notALink]: !(to || onClick),
    });

    if (!to || disabled) {
      return (
        <div
          className={ classes }
          title={ title }
          onClick={ (disabled ? null : onClick) }
          style={ this.props.style }
          children={ content } />
      );
    }
    return isLocalAddress(to)
      ? (
        <MuiLink
          className={ cx(styles.internalLink, classes) }
          download={ download }
          title={ title }
          style={ this.props.style }
          // openNew defaults to false for internal links
          target={ (openNew === undefined) ? null : (openNew ? '_blank' : null) }
          onClick={ onClick }
          to={ to }
          children={ content }
          component={ RouterLink } />
      )
      : (
        <MuiLink
          className={ cx(styles.externalLink, classes) }
          download={ download }
          href={ to }
          children={ content }
          onClick={ onClick }
          style={ this.props.style }
          // openNew defaults to true for external links
          target={ (openNew === undefined) ? '_blank' : (openNew ? '_blank' : null) }
          title={ title }
          // todo: need a prop to control noreferrer
          rel={ (openNew ? 'noopener noreferrer' : null) }  />
      );
  }

  render() {
    const { children, hidden } = this.props;
    if (hidden) return null;
    return this.wrap(children);
  }

}
