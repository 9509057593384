import { curry } from 'ramda';

// LocalStorage -> String -> LocalStorage
export const NamespacedLocalStorage = curry((ls, ns) => ({
  getItem: key => ls.getItem(`${ ns }:${ key }`),
  setItem: (key, val) => ls.setItem(`${ ns }:${ key }`, val),
  removeItem: key => ls.removeItem(`${ ns }:${ key }`),
  clear: () => {
    Object
      .keys(ls)
      .filter(key => key.startsWith(`${ ns }:`))
      .forEach(key => ls.removeItem(key));
  },
  get length() {
    return Object
      .keys(ls)
      .filter(key => key.startsWith(`${ ns }:`))
      .length;
  },
}));
