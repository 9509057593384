// modules
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Container, Typography, Card } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import { isEmpty, path } from 'ramda';
import { useQuery } from '@apollo/client';

// aliased
import Progress from 'components/Progress';
import Title from 'components/Title';
import { GET_ALL_PROJECTS } from 'lib/graphql/query';
import { connectionNodes } from 'lib/relay';

// local
import styles from './BetsView.module.scss';


const BetsView = () => {
  const { data, loading } = useQuery(GET_ALL_PROJECTS);
  const theme = useTheme();

  const palette = path(['palette', 'type'], theme);
  const bets = connectionNodes(path(['portfolio', 'bets'], data));

  return (
    <Container className={ cx(styles.container, styles[palette]) }>
      <Title title={ 'Portfolio' } />
      <Typography variant='h1' align={ 'center' }>{ 'Portfolio' }</Typography>

      <Progress hidden={ !loading }>{ 'Loading...' }</Progress>
      <Grid container={ true } spacing={ 4 }>
        { !loading && isEmpty(bets) ? (
          <Typography variant="h3">No Projects Found</Typography>
        ) : (
          bets.map(({ slug, name, logo }) => (
            <Grid
              item={ true }
              xs={ 12 }
              md={ 6 }
              lg={ 4 }
              component={ Link }
              key={ slug }
              to={ `/bet/${ slug }` }
            >
              <Card className={ styles.project } title={ `View ${ name }` }>
                { logo ? (
                  <img
                    className={ styles.logo }
                    src={ logo.url }
                    title={ name }
                    alt={ `${ name } logo` }
                  />
                ) : (
                  <Typography variant="h3" className={ styles.textColor }>
                    { name }
                  </Typography>
                ) }
              </Card>
            </Grid>
          ))
        ) }
      </Grid>
    </Container>
  );
};

export default BetsView;
