// modules
import React, { memo, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch, useHistory } from 'react-router-dom';
import conf from 'conf';
import { applyTo, pipe } from 'ramda';
import { SecureRoute, LoginCallback } from '@okta/okta-react';

// aliased
import ProtectedView from 'views/ProtectedView';
import Error from 'components/Error';
import Login from 'components/Login';
import NikeLoginCallback from 'components/NikeAuthProvider/NikeLoginCallback';
import { useGATracking } from 'lib/hooks/useGATracking';
import { propTypes, defaultProps } from 'lib/react';

const LoginComponent = props => (
  <LoginCallback { ...props } errorComponent={ Error } />
);

export default applyTo(() => {
  useGATracking(conf.GOOGLE_ANALYTICS_ID);
  const history = useHistory();

  // scroll to top of page on every transition
  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0));
    return () => unlisten();
  }, [history]);

  return (
    <Switch>
      <Route path='/login' exact={ true } component={ Login } />
      <Route
        path='/oidc/authorize_code/v1'
        component={ NikeLoginCallback } />
      <Route
        path='/callback'
        component={ LoginComponent } />
      <SecureRoute path='/' component={ ProtectedView } />
    </Switch>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
