import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import PropTypes from 'prop-types';

import { propTypes, defaultProps } from 'lib/react';

export default applyTo(({
  title,
}) => (
  <title>{ `${ title } | Valiant Labs` }</title>
), pipe(
  propTypes({
    title: PropTypes.string,
  }),
  defaultProps({
    title: '',
  }),
  memo,
));
