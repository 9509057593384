import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { Switch, Route } from 'react-router-dom';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import NotFound from 'components/NotFound';

// local
import TeamMembers from './components/TeamMembers';
import TeamMember from './components/TeamMember';

export default applyTo(() => {
  return (
    <Switch>
      <Route path={ '/team' } exact={ true } component={ TeamMembers } />
      <Route path={ '/team/:username' } exact={ true } component={ TeamMember } />
      <Route component={ NotFound } />
    </Switch>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
