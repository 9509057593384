// modules
import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe, path, prop, propOr } from 'ramda';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  Container,
  Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import { GET_BET_DETAILS } from 'lib/graphql/query';
import Progress from 'components/Progress';
import Title from 'components/Title';
import User from 'components/User';
import Link from 'components/Link';

// local
import styles from './BetView.module.scss';
import Project from './components/Project';
import NotFound from 'components/NotFound';

export default applyTo(() => {
  const match = useRouteMatch();
  const currentUrl = prop('url', match);
  const currentPath = prop('path', match);
  
  const { data, loading } = useQuery(GET_BET_DETAILS, {
    variables: { slug: path(['params', 'slug'], match) },
  });

  if (loading) return (<Progress>{ 'Loading Project...' }</Progress>);

  const bet = prop('bet', data);
  const { name } = bet;
  const aliases = propOr([], 'aliases', bet);

  return (
    <>
      <Title title={ name } />
      <div className={ styles.contextualHeader }>
        <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant='h2' component={ Link } to={ currentUrl }>
              { name }
            </Typography>
            {
              !!aliases.length && (
                <div className={ styles.aliases }>{
                  `(aka: ${ aliases.join(', ') })`
                }</div>
              )
            }
          </div>
        
        </Container>
      </div>
      <Switch>
        <Route path={ currentPath } exact={ true } component={ Project } />
        <Route path={ `${ currentPath }/user` } exact={ true } component={ User } />
        <Route component={ NotFound } />
      </Switch>
    </>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo
));
