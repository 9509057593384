import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Query {
    authToken: String
    orderBySlug: [Order]
    orderByDateRange: [Order]
    getProjectBySlug: Project!
    # getAllProjects: Portfolio
  }

  type Order {
    id:  ID
    appId: String
    createdDate:  String
    currency:  String
    date:  String
    items:  [Item]
    locale:  String
    orderId:  String
    status:  String
    total:  Int
  }

  type Item {
    colorCode: String
    marketPlace: Marketplace
    quantity: Int
    sku: String
    styleColor: String
  }

  type Marketplace {
    merchGroup: String
  }

  type Project {
    name: String!
    slug: String!
    phase: Phase!
    aliases: [String]
    appIds: [String]
    jiraProjects: [String]
  }

  type User {
    firstName: String!
    lastName: String!
    email: String!
    avatar: String
    role: String
    jobTitle: String
    projects: [String]
    locale: String
    zone: String
  }
`;
