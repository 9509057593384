import * as R from 'ramda';
import { jwtDecode } from 'jwt-decode';
import { isPast } from 'date-fns';

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('')
    .map(char => {
      return `%${  (`00${ char.charCodeAt(0).toString(16) }`).slice(-2) }`;
    })
    .join(''));

  return JSON.parse(jsonPayload);
};

// JSON JWT -> Date
// date at which a JWT was issued
export const jwtIssuedAt = R.unless(R.isNil, R.pipe(
  R.prop('iat'),
  R.unless(R.isNil, date => new Date(date * 1000)),
));
// JSON JWT -> Date
// date at which a JWT expires
export const jwtExpiresAt = R.unless(R.isNil, R.pipe(
  R.prop('exp'),
  R.unless(R.isNil, date => new Date(date * 1000)),
));

// String JWT -> Boolean
// is a jwt expired?
export const jwtIsExpired = R.pipe(jwtDecode, jwtExpiresAt, isPast);

export const hasCodeInUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Boolean(searchParams.get('code'));
};
