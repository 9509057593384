// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { pipe, applyTo } from 'ramda';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// aliased
import FlexCenter from 'components/FlexCenter';
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './NotFound.module.scss';

export default applyTo(({ title, history }) => (
  <FlexCenter>
    { title && <Typography variant='h1'>{ title }</Typography> }
    <Typography variant='h1'>Not Found</Typography>
    <div className={ styles.buttons }>
      { /* eslint-disable-next-line react/jsx-no-bind */ }
      <Button className={ styles.button } onClick={ _ => history.goBack() }>
        { 'Back' }
      </Button>
      { /* eslint-disable-next-line react/jsx-no-bind */ }
      <Button className={ styles.button } onClick={ _ => window.location.reload() }>
        { 'Retry' }
      </Button>
    </div>
  </FlexCenter>
), pipe(
  propTypes({
    title: PropTypes.string,
  }),
  defaultProps({

  }),
  withRouter,
  memo,
));
