// modules
import { unparse } from 'papaparse';
import { curry } from 'ramda';

// Array<Object> -> String
export const toCsv = unparse;

// (String, File|Blob) -> void
export const downloadFile = curry((filename, content) => {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = content;
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
});

// String, [] -> void
export const downloadCsv = curry((filename, items) => {
  const csv = toCsv(items);
  const blob = new Blob([csv], { type: 'text/csv' });
  const href = window.URL.createObjectURL(blob);
  downloadFile(filename, href);
});

// String -> Object -> Undefined
export const downloadData = curry((filename, data) => {
  const uri = `data:application/json;charset=utf-8,${ encodeURIComponent(JSON.stringify(data)) }`;
  return downloadFile(filename, uri);
});
