// import { VLconsole } from './fragment';
import { gql } from '@apollo/client';

export const WHOAMI = gql`
query WhoAmI {
  whoami {
    id
    email
    #firstName
    #lastName
  }
}
`;

export const GET_ALL_PROJECTS = gql`
query GetAllProjects {
  portfolio {
    bets(input: {
      first: 100
    }) {
      edges {
        node {
          id
          url
          slug
          name
          active
          aliases
          logo { url }
          stage {
            name
            description
          }
        }
      }
    }
  }
}
`;

export const GET_BETS_BY_STAGE = gql`
query GetBetsByStage {
  portfolio {
    stages {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          description
          bets {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                url
                slug
                name
                active
                aliases
                logo { url }
                stage {
                  name
                  description
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

const BET_FIELDS_FRAGMENT = gql`
fragment BetFields on Bet {
    id
    slug
    name
    description
    url
    active
    aliases
    # apps {
    #   edges {
    #     node {
    #       id
    #       name
    #       appId
    #       url
    #     }
    #   }
    # }
    jiraProject {
      id
      key
      url
    }
    confluenceSpace {
      id
      key
      url
    }
    scmOrg {
      __typename
      url
    }
    stage {
      name
    }
    logo { url }
    apis {
      edges {
        node {
          __typename
          name
          slug
          authProviders
        }
      }
    }
    apps {
      edges {
        node {
          active
          name
          type
          url
        }
      }
    }
    # sales(input: { timePeriod: ALL_TIME }) {
    #   insights {
    #     totalRevenue
    #     totalOrders
    #     totalUnits
    #   }
    # }
  }`;

export const GET_BET_DETAILS = gql`
${ BET_FIELDS_FRAGMENT }
query GetBets($slug: String!) {
  bet(slug: $slug) {
    ...BetFields
  }
}`;

