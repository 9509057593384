import React from 'react';
// import PropTypes from 'prop-types';
import { Typography, Container } from '@material-ui/core';
import { applyTo, pipe } from 'ramda';
import { useParams, useLocation } from 'react-router-dom';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import Breadcrumbs from 'components/Breadcrumbs';

// local

const BREADCRUMBS = {
  user: 'User',
};

export default applyTo(() => {
  const { username } = useParams();
  const location = useLocation();

  return (
    <Container style={{ marginTop: 25 }}>
      <Breadcrumbs path={ location.pathname } data={ BREADCRUMBS } />
      <Typography variant="h1">User { username }</Typography>
    </Container>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
));
