import React, { memo, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { Container, Tab, Tabs } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

// aliased
import Title from 'components/Title';
import { propTypes, defaultProps } from 'lib/react';

// images
import oktaLogo from 'assets/okta-logo.png';
import swooshLogo from 'assets/swoosh-logo.png';
import jwtLogo from 'assets/jwt-logo.svg';

// local
import JwtView from './JwtView';
import OktaView from './OktaView';
import ConsumerView from './ConsumerView';

const TABS = [
  {
    key: 'okta',
    component: OktaView,
    icon: oktaLogo,
    iconWidth: '75px',
    title: 'Okta Auth',
  },
  {
    key: 'consumer',
    component: ConsumerView,
    icon: swooshLogo,
    iconWidth: '75px',
    label: 'accounts.nike.com',
    title: 'Consumer Auth',
  },
  {
    key: 'jwt',
    component: JwtView,
    icon: jwtLogo,
    iconWidth: '100px',
    title: 'JWT Decoder',
  },
];

export default R.applyTo(() => {

  const { provider: providerKey } = useParams();
  const history = useHistory();

  const providerTab = R.applyTo(providerKey, R.pipe(
    key => TABS.findIndex(({ key: tabKey }) => tabKey === key),
    index => (index < 0 ? 0 : index),
  ));

  useEffect(() => {
    if (providerKey) return;
    history.replace(`/auth/${ R.path([0, 'key'], TABS) }`);
  }, [providerKey, TABS]);

  const handleProviderTabChange = useCallback((event, tab) => {
    history.replace(`/auth/${ R.path([tab, 'key'], TABS) }`);
  }, [history]);

  return (
    <Container>
      <Title title={ TABS[providerTab].title || 'Auth' } />
      <Tabs
        value={ providerTab }
        variant="fullWidth"
        onChange={ handleProviderTabChange }
        style={{
          marginBottom: '15px',
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        }}
      >
        {
          TABS.map((tab, i) => (
            <Tab
              key={ tab.key }
              icon={ <img src={ tab.icon } style={{ width: tab.iconWidth }} /> }
              label={ tab.label }
              title={ ((providerTab !== i) ? `View ${ tab.title }` : null) }
              style={{ padding: '20px' }} />
          ))
        }
      </Tabs>
      {
        // do not unmount tabs, so they can maintain state between transitions
        TABS.map(({ key, component: Component }, i) => (
          <div
            key={ key }
            style={{
              display: (i === providerTab) ? 'block' : 'none',
            }} >
            <Component />
          </div>
        ))
      }
    </Container>
  );
}, R.pipe(
  propTypes({}),
  defaultProps({}),
  memo
));
