/* eslint-disable react/no-multi-comp */
// modules
import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';

// local
import styles from './NestedData.module.scss';


const NestedData = ({ data, style, className, isChild, ...props }) => {
  const classNames = cx(styles.nestedData, className);
  if (Array.isArray(data)) {
    return (
      <div style={{ padding: '0 1px' }}>
        {
          R.addIndex(R.chain)((item, i) => [
            (
              <div key={ i } className={ styles.arrayItem }>
                <NestedData
                  data={ item }
                  style={{ padding: '0 1px', ...style }} />
              </div>
            ),
          ], data)
        }
      </div>
    );
  }
  
  if (typeof data !== 'object' || isValidElement(data)) {
    return (
      <span { ...props } style={ style }>
        { data }
      </span>
    );
  }

  return (
    <div>
      { /* <div
        style={{
          position: 'absolute',
          display: isChild ? 'absolute' : 'none',
          marginLeft: '-10px',
          marginTop: '-3px',
          top: '50%',
          width: '10px',
          height: '6px',
          background: 'rgba(0, 0, 0, 0.1)',
        }} /> */ }
      <table
        { ...props }
        className={ classNames }
        style={{
          borderCollapse: 'separate',
          borderSpacing: 0,
          borderRadius: '5px',
          overflow: 'hidden',
          width: '100%',
          display: 'flex',
          ...style,
        }}
      >
        <tbody>
          {
            R.applyTo(data, R.pipe(
              R.cond([
                [val => (val instanceof Map), map => [...map.entries()]],
                [R.T, R.toPairs],
              ]),
              // don't render undefined keys
              R.filter(([key, value]) => (value !== undefined)),
              R.map(([key, value]) => (
                <tr key={ key }>
                  <td className={ styles.key }>
                    { key }
                  </td>
                  <td className={ styles.value } style={{ position: 'relative' }}>
                    {
                      (typeof value === 'object')
                        ? (<NestedData data={ value } isChild={ true } />)
                        : value
                    }
                  </td>
                </tr>
              )),
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

NestedData.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  isChild: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default NestedData;
