import React, { memo, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { applyTo, curry, pipe, path, pathOr, evolve, map, prop, filter } from 'ramda';

// aliased
import GraphiQL from 'components/GraphiQL';
import Title from 'components/Title';
import { connectionNodes } from 'lib/relay';
import config from 'conf';

const LIST_BET_APIS = gql`query ListBetApis {
  portfolio {
    bets(input: { first: 100 }) {
      edges {
        node {
          name
          slug
          active
          apis {
            edges {
              node {
                name
                slug
                url
                authProviders
                ...on GraphqlApi {
                  examples {
                    edges {
                      node {
                        name
                        query
                        variables
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

const OWN_GRAPHS = [
  {
    name: 'Valiant Labs',
    slug: 'vl',
    active: true,
    apis: [{
      name: 'Private',
      slug: 'vl-private',
      url: `${ config.API_HOST }/graphql`,
      authProviders: ['OKTA'],
      examples: [
        {
          name: 'Who Am I?',
          variables: {},
          query: `query WhoAmI {
  whoami {
    email
  }
}`,
        },
        {
          name: 'VL portfolio',
          variables: {},
          query: `query VLPortfolio {
  portfolio {
    bets {
      edges {
        node {
          name
        }
      }
    }
  }
}`,
        },
        {
          name: 'Bet sales',
          variables: { slug: 'nbg' },
          query: `query BetSales($slug: String!) {
  bet(slug: $slug) {
    name
    sales {
      insights {
        totalOrders
        totalRevenue
        totalUnits
      }
    }
  }
}`,
        },
        {
          name: 'Bet links',
          variables: { slug: 'nbg' },
          query: `query BetLinks($slug: String!) {
  bet(slug: $slug) {
    name
    url
    jiraProject { url }
    scmOrg { url }
    apps { edges { node { name url } } }
  }
}`,
        },
        {
          name: 'Get anything by ID',
          variables: { id: 'QmV0OjMzZjEyMjk5LTIwZTYtNDg3ZC04MjM3LWYxMzY0YzBhN2VjOA==' },
          query: `query GetNode($id: ID!) {
  node(id: $id) {
    ... on Bet {
      name
    }
  }
}`,
        },
      ],
    }],
  }];

const isNodeOfType = curry((type, node) => {
  return prop('__typename', node) === type;
});


const parseGraphqlErr = path(['networkError', 'result', 'errors', 0, 'message']);

const GraphiQLView = () => {

  const { data, loading, error } = useQuery(LIST_BET_APIS, {
    variables: {},
  });

  const graphs = useMemo(() => {
    if (loading) return null;

    return applyTo(data, pipe(
      pathOr([], ['portfolio', 'bets']),
      connectionNodes,
      map(evolve({
        apis: pipe(
          connectionNodes,
          filter(isNodeOfType('GraphqlApi')),
          map(evolve({
            examples: connectionNodes,
          }))
        ),
      })),
      // only display bets that have one or more graphs
      filter(node => pathOr(0, ['apis', 'length'], node) > 0),
      graphs => [...OWN_GRAPHS, ...graphs],
    ));
  }, [data, loading, OWN_GRAPHS]);

  if (error) return (<pre>{ parseGraphqlErr(error) }</pre>);

  return (
    <>
      <Title title={ 'GraphQL Playground' } />
      <GraphiQL
        graphs={ graphs }
        loading={ loading } />
    </>
  );
};

GraphiQLView.propTypes = {};
GraphiQLView.defaultProps = {};

export default memo(GraphiQLView);
