import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe, head } from 'ramda';
import { Avatar } from '@material-ui/core';
import cx from 'classnames';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import { parseUserEmail } from 'lib/string';

import styles from './UserAvatar.module.scss';

export default applyTo(({ user, size }) => {
  const [isAvatarLoaded, setAvatarLoaded] = useState(false);
  if (!user) return null;

  const inferred = parseUserEmail(user.email);
  const firstName = user.firstName || inferred[0];
  const lastName = user.lastName || inferred[1];
  const name = [firstName, lastName]
    .filter(Boolean)
    .join(' ');
  
  return (
    <div className={ styles.container }>
      <Avatar
        className={ cx(styles.avatar, {
          [styles.fadeIn]: isAvatarLoaded,
        }) }
        title={ `Logged in as ${ name }` }
        src={ null }
        size={ +size }
        imgProps={{
          onLoad: () => setAvatarLoaded(true),
        }}
      >
        <p>{ head(firstName || '') } { head(lastName || '') }</p>
      </Avatar>
      <Avatar
        className={ cx(styles.loader, {
          [styles.fadeOut]: isAvatarLoaded,
        }) }
        title={ `Logged in as ${ name }` }
        size={ +size }
      >
        <></>
      </Avatar>
    </div>
  );
}, pipe(
  propTypes({
    size: PropTypes.number,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  defaultProps({
    size: 50,
  }),
  memo,
));
