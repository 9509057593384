// modules
import React, { memo, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { pipe, applyTo } from 'ramda';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
// aliased
import { propTypes, defaultProps } from 'lib/react';

export default applyTo(() => {
  const { oktaAuth } = useOktaAuth();

  useEffect(async () => {
    oktaAuth.tokenManager.clear(); // log user out of console (not okta)
    await oktaAuth.signOut(); // log user out of okta (and console)
    console.log('logged out');
  });

  return <Redirect to={ '/login' } />;
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
