import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { Typography, Container } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import {
  applyTo,
  cond,
  equals,
  map,
  path,
  pathOr,
  pipe,
  prop,
  replace,
} from 'ramda';

// components
import Progress from 'components/Progress';
import Link from 'components/Link';
import Error from 'components/Error';

// images
import InactiveIcon from 'assets/inactive-icon.png';
import GraphQLIcon from 'assets/graphql-icon.svg';
import RestApiIcon from 'assets/rest-api-icon.png';
import GithubIcon from 'assets/github-icon.png';
import JiraIcon from 'assets/jira-icon.png';
import ConfluenceIcon from 'assets/confluence-icon.png';
import iosIcon from 'assets/ios-icon.png';
import wwwIcon from 'assets/www-icon.png';


// lib
import { GET_BET_DETAILS } from 'lib/graphql/query';
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './Project.module.scss';


// eslint-disable-next-line react/prop-types
const Card = ({ icon, text, className, ...rest }) => (
  <Link className={ cx(styles.card, className) } { ...rest }>
    <div className={ styles.top }>{ icon }</div>
    <div className={ styles.bottom }>
      <p>{ text }</p>
    </div>
  </Link>
);

// eslint-disable-next-line complexity
export default applyTo(() => {
  const { slug } = useParams();

  const { data, loading, error } = useQuery(GET_BET_DETAILS, {
    variables: { slug },
  });

  const bet = path(['bet'], data);

  if (loading) return (<Progress>{ 'Loading Project...' }</Progress>);
  if (error) return <Error error={ error.message } />;

  const apis = applyTo(bet, pipe(
    pathOr([], ['apis', 'edges']),
    map(prop('node')),
  ));

  const apps = applyTo(bet, pipe(
    pathOr([], ['apps', 'edges']),
    map(prop('node')),
  ));

  return (
    <Container>
      <div className={ styles.header }>
        {
          (bet.logo || !bet.active) && (
            <div className={ styles.logoWrap }>
              {
                bet.logo && (
                  <img
                    className={ styles.logo }
                    alt={ `${ prop('name', bet) } logo` }
                    src={ path(['logo', 'url'], bet) } />
                )
              }
              {
                !bet.active && (
                  <img
                    title={ 'Project is Inactive' }
                    className={ styles.inactive }
                    src={ InactiveIcon } />
                )
              }
            </div>
          )
        }
        
      </div>
      { /* <div>
        <Typography variant="h1">Order Insights</Typography>
        <div className={ styles.linkRow }>
          <div>
            <Typography variant="h5">Total Revenue</Typography>
            <Typography
              variant="h2"
              className={ styles.stat }
            >
            ${ (totalRevenue / 100).toLocaleString('en-US', { minimumFractionDigits: 2 }) }
            </Typography>
          </div>
          <div>
            <Typography variant="h5">Total Orders</Typography>
            <Typography
              variant="h2"
              className={ styles.stat }
            >
              { totalOrders }
            </Typography>
          </div>
          <div>
            <Typography variant="h5">Total Units</Typography>
            <Typography
              variant="h2"
              className={ styles.stat }
            >
              { totalUnits }
            </Typography>
          </div>
        </div>
      </div> */ }
      { bet.description && (
        <div className={ styles.description }>
          { bet.description }
        </div>
      ) }
      { bet.url && (
        <Link to={ bet.url } className={ styles.url }>
          <OpenInNewIcon fontSize='small' style={{ marginRight: 10 }} />
          { bet.url }
        </Link>
      ) }
      {
        !!(path(['scmOrg'], bet) || path(['jiraProject'], bet) || path(['confluenceSpace'], bet)) && (
          <div>
            <Typography variant="h1">Project</Typography>
            <div className={ styles.row }>
              {
                !!path(['scmOrg'], bet) && (
                  <Card
                    text={
                      applyTo(bet, pipe(
                        path(['scmOrg', 'url']),
                        url => new URL(url),
                        prop('pathname'),
                        replace(/\//g, ''),
                      ))
                    }
                    title={ 'View GitHub Org' }
                    to={  path(['scmOrg', 'url'], bet) }
                    icon={ <img src={ GithubIcon } /> } />
                )
              }
              {
                path(['jiraProject'], bet) && (
                  <Card
                    text={ path(['jiraProject', 'key'], bet) }
                    title={ 'View Jira Project' }
                    to={ path(['jiraProject', 'url'], bet) }
                    icon={ <img src={ JiraIcon } /> } />
                )
              }
              {
                path(['confluenceSpace'], bet) && (
                  <Card
                    text={ path(['confluenceSpace', 'key'], bet) }
                    title={ 'View Confluence Project' }
                    to={ path(['confluenceSpace', 'url'], bet) }
                    icon={ <img src={ ConfluenceIcon } /> } />
                )
              }
            </div>
          </div>
        )
      }
      {
        !!apps.length && (
          <div>
            <Typography variant="h1">Apps</Typography>
            <div className={ styles.row }>
              {
                map(({ type, name, url, active }) => (
                  <Card
                    key={ name }
                    className={ styles.app }
                    text={ name }
                    title={ url ? `View ${ name } app` : null }
                    to={ url }
                    icon={
                      <div className={ styles.icon } >
                        {
                          !active ? (
                            <img className={ styles.inactive } src={ InactiveIcon } />
                          ) : null
                        }
                        {
                          applyTo(type, cond([
                            [equals('IOS'), _ => (<img src={ iosIcon } />)],
                            [equals('WEB'), _ => (<img src={ wwwIcon } />)],
                          ]))
                        }
                      </div>
                    } />
                ), apps)
              }
            </div>
          </div>
        )
      }
      {
        !!apis.length && (
          <div>
            <Typography variant="h1">APIs</Typography>
            <div className={ styles.row }>
              {
                map(({ __typename: type, name, slug }) => (
                  <Card
                    key={ slug }
                    text={ name }
                    title={ (type === 'GraphqlApi' ? `View ${ name } graph in the API Playground` : null) }
                    to={ (type === 'GraphqlApi' ? `/graphql?host=${ slug }` : null) }
                    icon={
                      applyTo(type, cond([
                        [equals('GraphqlApi'), _ => (<img src={ GraphQLIcon } />)],
                        [equals('RestApi'), _ => (<img src={ RestApiIcon } />)],
                      ]))
                    } />
                ), apis)
              }
            </div>
          </div>
        )
      }
    </Container>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
