// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { applyTo, pipe } from 'ramda';

// import { propTypes, defaultProps } from 'lib/react';

export const COLUMNS = [
  { field: 'id', headerName: 'Node ID', width: 150 },
  // {
  //   // TODO: update this
  //   field: 'orderId',
  //   headerName: 'Order ID',
  //   width: 200,
  //   renderCell: applyTo(({ value }) => (
  //     <Link to={ `${ window.location.pathname }/${ value }` }>{ value }</Link>
  //   ), pipe(
  //     propTypes({
  //       value: PropTypes.string,
  //     }),
  //     defaultProps({
  //       value: '',
  //     }),
  //   )),
  // },
];
