import React, { useState, useCallback } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Typography, Icon, Card } from '@material-ui/core';
import { differenceInMinutes, formatDistanceStrict } from 'date-fns/fp';

// aliased
import NestedData from 'components/NestedData';
import { useInterval } from 'lib/hooks/useInterval';
import { useForceUpdate } from 'lib/hooks/useForceUpdate';
import { parseJwt, jwtExpiresAt, jwtIssuedAt } from 'lib/auth';

// local
import styles from './JwtView.module.scss';
import TokenField from '../components/TokenField';

const POLL_INTERVAL = 5; // seconds

// duck-type known jwt types
const guessTokenType = R.cond([
  [({ iss = '' }) => R.includes('okta', iss), R.cond([
    [R.has('cid'), _ => 'Okta.Access'],
    [R.T, _ => 'Okta.Identity'],
  ])],
  [({ aud = '' }) => R.includes('nike', aud), _ => 'Consumer.Access'],
  [({ iss = '' }) => R.includes('nike', iss), _ => 'Consumer.Identity'],
  [R.T, _ => undefined],
]);

// const ThingByType = {
//   'Okta.Access': _ => {},
//   'Okta.Identity': _ => {},
//   'Consumer.Access': _ => {},
//   'Consumer.Identity': _ => {},
// };

// eslint-disable-next-line max-statements, complexity
export default () => {
  const [jwt, setJwt] = useState('');
  useInterval(useForceUpdate(), POLL_INTERVAL * 1000);

  const json = R.tryCatch(parseJwt, _ => undefined)(jwt);

  const now = new Date();
  const issuedAt = jwtIssuedAt(json) || now;
  const expiresAt = jwtExpiresAt(json) || now;

  const handleSetJwt = useCallback(event => setJwt(event.target.value), [setJwt]);

  const isInvalid = (jwt && !json);

  return (
    <Grid className={ styles.jwtView } container={ true } spacing={ 2 }>
      <Grid item={ true } xs={ 12 }>
        <Card style={{ display: 'flex' }}>
          <NestedData
            style={{ fontSize: '18px', width: '100%' }}
            data={
              json
                ? ({
                  'Token Type': json
                    ? R.applyTo(json, R.unless(R.isNil, R.pipe(
                      guessTokenType,
                      R.unless(R.isNil, R.pipe(
                        R.replace('.', ' - '),
                        str => `${ str } Token`,
                      )),
                    )))
                    : undefined,
                  TTL: {
                    Issued: (
                      <span title={ issuedAt.toISOString() }>
                        { `${ formatDistanceStrict(issuedAt, new Date()) } ago` }
                      </span>
                    ),
                    [(now > expiresAt) ? 'Expired' : 'Expires']: (
                      <div
                        style={{
                          color: differenceInMinutes(new Date(), expiresAt) <= 5
                            ? 'red'
                            : 'inherit',
                        }}>
                        <span title={ expiresAt.toISOString() }>
                          {
                            (now > expiresAt)
                              ? `${ formatDistanceStrict(new Date(), expiresAt) } ago`
                              : formatDistanceStrict(expiresAt, new Date())
                          }
                        </span>
                      </div>
                    ),
                  },
                })
                : undefined
            } />
        </Card>
      </Grid>
      <Grid item={ true } xs={ 6 }>
        <Card style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h4' align={ 'center' } style={{ background: 'rgba(0, 0, 0, 0.1)', padding: '10px' }}>
            <Icon style={{ marginRight: '10px', verticalAlign: 'middle' }}>{ 'key' }</Icon>
            <span style={{ verticalAlign: 'middle' }}>{ 'JWT' }</span>
          </Typography>
          <div style={{ display: 'flex' }}>
            <TokenField
              className={ styles.tokenField }
              inputClassName={
                cx(styles.code, styles.input, {
                  [styles.invalid]: isInvalid,
                  [styles.valid]: json && !isInvalid,
                })
              }
              title={ 'JWT' }
              placeholder={ 'Paste JWT Here' }
              editable={ true }
              onChange={ handleSetJwt }
            >
              { jwt }
            </TokenField>
          </div>
        </Card>
      </Grid>
      <Grid item={ true } xs={ 6 }>
        <Card style={{ display: 'flex', flexDirection: 'column'  }}>
          <Typography variant='h4' align={ 'center' } style={{ background: 'rgba(0, 0, 0, 0.1)', padding: '10px' }}>
            <Icon style={{ marginRight: '10px', verticalAlign: 'middle' }}>{ 'code' }</Icon>
            <span style={{ verticalAlign: 'middle' }}>{ 'JSON' }</span>
          </Typography>
          <div style={{ display: 'flex' }}>
            <TokenField
              className={ styles.tokenField }
              inputClassName={
                cx(styles.code, styles.json, {
                  [styles.invalid]: isInvalid,
                  [styles.valid]: json && !isInvalid,
                })
              }
              title={ 'JSON' }
              placeholder={ isInvalid ? 'Invalid JWT' : 'View JSON Here' }
              style={{ color: (isInvalid ? 'red' : null) }}
            >
              {
                json
                  ? JSON.stringify(json, null, 2)
                  : ''
              }
            </TokenField>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};
