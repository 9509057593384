import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Switch,
  Divider,
} from '@material-ui/core';
import { Menu as MenuIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { applyTo, pipe, path, find, propEq, replace } from 'ramda';
import { capitalize } from '@vl/js-lib/browser/string';

import UserAvatar from 'components/UserAvatar';
import Link from 'components/Link';
import ErrorBoundary from 'components/ErrorBoundary';
import Theme from 'components/Theme';
import config from 'conf';
import { propTypes, defaultProps } from 'lib/react';
import logoWhite from 'assets/logoWhite.png';
import logoBlack from 'assets/logoBlack.png';

// local
import Menu from './components/Menu';
import styles from './Layout.module.scss';
import './base.scss';


const STORED_THEME = window.localStorage.getItem('theme') || 'light';
const ENV = find(propEq('active', true), config.ENVS);


export default applyTo(({ className, children, user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [themeToggle, setThemeToggle] = useState(STORED_THEME === 'light');
  const [theme, setTheme] = useState(STORED_THEME);

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [setIsMenuOpen, isMenuOpen]);

  const handleThemeToggle = useCallback(() => {
    setThemeToggle(!themeToggle);
    setTheme(!themeToggle ? 'light' : 'dark');
    window.localStorage.setItem('theme', themeToggle ? 'dark' : 'light');
  }, [setThemeToggle, themeToggle, setTheme]);

  const handleChangeEnv = useCallback(event => {
    const name = event.target.value;
    const active = find(propEq('name', name), config.ENVS);

    if (!active) return;
    const currentPath = [
      window.location.pathname,
      window.location.search,
      window.location.hash,
    ].join('');
    // redirect to new environment, with same path, query, and hash
    window.location.href = replace(/\/$/, '', active.url) + currentPath;
  }, []);

  if (isMenuOpen) {
    return (
      <Theme theme={ theme }>
        <CssBaseline />
        <Menu
          user={ user }
          onClose={ handleMenuToggle }
          isLightTheme={ themeToggle } />
      </Theme>
    );
  }

  return (
    <ErrorBoundary className={ styles.layoutErrorBoundary }>
      <Theme theme={ theme }>
        <CssBaseline />
        <div className={ cx(styles.site, themeToggle ? styles.light : styles.dark, className) }>
          <AppBar component='header' position="static">
            <Container>
              <Toolbar style={{ width: '100%', padding: 0 }}>
                <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    style={{ marginLeft: -12 }}
                    className={ styles.menu }
                    onClick={ handleMenuToggle } >
                    <MenuIcon />
                  </IconButton>
                  <Link to="/" title={ 'Go Home' } className={ styles.logo }>
                    <img src={ themeToggle ? logoBlack : logoWhite } alt='VL' className={ styles.logoImage } />
                  </Link>
                </div>
                <Link to={ `/team/${ path(['id'], user) }` } style={{ textDecoration: 'none' }} >
                  <UserAvatar user={ user } />
                </Link>
              </Toolbar>
            </Container>
          </AppBar>

          <main className={ styles.main } id="main">
            { children }
          </main>

          <footer>
            <Container>
              <Grid container={ true } spacing={ 2 } className={ styles.footer }>
                <Grid item={ true } xs={ 12 } md={ 6 }>
                  <Typography variant="h5">Contact</Typography>
                  <Divider />
                  <nav>
                    <Link
                      to={
                        // eslint-disable-next-line max-len
                        `mailto:${ config.SUPPORT_EMAIL }?subject=Support Request: VL Console (${ capitalize(ENV.name) } ${ ENV.version })`
                      }>
                      <OpenInNewIcon fontSize='small' style={{ marginRight: 10 }} />
                      Email Support
                    </Link>
                  </nav>
                </Grid>
                <Grid item={ true } xs={ 12 } md={ 6 }>
                  <Typography variant="h5">Settings</Typography>
                  <Divider />
                  <nav>
                    <Typography>
                      { 'Environment: ' }
                      <select
                        className={ cx(styles.dropdown) }
                        onChange={ handleChangeEnv }
                        title={ 'Change Environment' }
                        value={ config.env }
                      >
                        {
                          config.ENVS.map(({ name, version }) => {
                            return (
                              <option key={ name } value={ name }>
                                { `${ capitalize(name) }${ version ? ` ${ version }` : '' }` }
                              </option>
                            );
                          })
                        }
                      </select>
                    </Typography>
                    <Typography component='div' style={{ marginTop: -15 }}>
                      <Grid
                        component="label"
                        container={ true }
                        alignItems="center"
                        spacing={ 1 }
                        style={{ cursor: 'pointer' }}
                        title={ themeToggle ? 'Change to Dark Mode' : 'Change to Light Mode' }
                      >
                        <Grid item={ true }>Dark</Grid>
                        <Grid item={ true }>
                          <Switch
                            checked={ themeToggle }
                            onChange={ handleThemeToggle } />
                        </Grid>
                        <Grid item={ true }>Light</Grid>
                      </Grid>
                    </Typography>
                  </nav>
                </Grid>
              </Grid>
            </Container>
          </footer>
        </div>
      </Theme>
    </ErrorBoundary>
  );
}, pipe(
  propTypes({
    children: PropTypes.node,
    className: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  defaultProps({
    children: null,
    className: null,
    user: null,
  }),
  memo,
));
