import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { applyTo, split, pipe, not, map, pair, isEmpty, toPairs, join, filter, merge, keys, omit, includes, fromPairs } from 'ramda';
import Link from 'components/Link';
import { capitalize } from '@vl/js-lib/browser/string';

import { propTypes, defaultProps } from 'lib/react';

const pathToItems = pipe(
  split('/'),
  filter(
    pipe(
      isEmpty,
      not,
    ),
  ),
  map(item => pair(item, capitalize(item))),
  items => {
    let prefix = '';
    return map(([path, label]) => {
      prefix = join('/', [prefix, path]);
      return [prefix, label];
    }, items);
  },
  fromPairs,
);

const getItems = (path, data) => {
  const parts = pathToItems(path);
  const validKeys = keys(parts);
  const items = merge(parts, data);
  return omit(filter(
    key => !includes(key, validKeys),
    keys(items),
  ), items);
};

export default applyTo(({ path, data = {}, className }) => {
  const items = getItems(path, data);
  return (
    <Breadcrumbs className={ className }>
      { toPairs(items).map(([key, value]) => (
        <Link to={ key } key={ key }>
          <Typography>
            { data[value] || value }
          </Typography>
        </Link>
      )) }
    </Breadcrumbs>
  );
}, pipe(
  propTypes({
    className: PropTypes.string,
    data: PropTypes.shape({}),
    path: PropTypes.string,
  }),
  defaultProps({
    className: null,
    data: {},
    path: '',
  }),
  memo,
));
