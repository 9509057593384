import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Tab, Tabs, Icon } from '@material-ui/core';
import { toHumanJSON } from '@vl/js-lib/browser/object';

// aliased
import { parseJwt } from 'lib/auth';

// local
import styles from './Token.module.scss';
import TokenField from '../components/TokenField';

export default function Token({ token, onTabChange, tab, onCopy }) {

  const json = token
    ? toHumanJSON(R.tryCatch(parseJwt, R.prop('message'))(token))
    : '';

  return (
    <div className={ styles.token }>
      <Tabs
        value={ tab }
        onChange={ onTabChange }
        variant="fullWidth"
      >
        <Tab
          title={ (tab !== 0 ? 'View JSON' : '') }
          icon={ <Icon>{ 'code' }</Icon> }
          label="JSON" />
        <Tab
          title={ (tab !== 1 ? 'View JWT' : '') }
          icon={ <Icon>{ 'key' }</Icon> }
          label="JWT" />
      </Tabs>

      <TokenField
        style={{
          overflow: 'scroll',
          width: '100%',
          display: (tab === 0 ? 'block' : 'none'),
        }}
        blur={ false }
        title={ 'JSON' }
      >
        { json }
      </TokenField>
      <TokenField
        style={{
          textWrap: 'wrap',
          wordWrap: 'break-word',
          display: (tab === 1 ? 'block' : 'none'),
        }}
        blur={ true }
        title={ 'JWT' }
      >
        { token }
      </TokenField>

    </div>
  );
}
Token.propTypes = {
  onCopy: PropTypes.func,
  onTabChange: PropTypes.func,
  tab: PropTypes.number,
  token: PropTypes.string,
};

Token.defaultProps = {
  onCopy: _ => {},
  onTabChange: _ => {},
  tab: PropTypes.number,
  token: PropTypes.string,
};
