// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { ThemeProvider } from '@material-ui/styles';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import DARK_THEME from 'lib/themes/dark';
import LIGHT_THEME from 'lib/themes/light';

export default applyTo(({ children, theme }) => {
  
  return (
    <ThemeProvider theme={ theme === 'light' ? LIGHT_THEME : DARK_THEME }>
      { children }
    </ThemeProvider>
  );
},
pipe(
  propTypes({
    children: PropTypes.node,
    theme: PropTypes.oneOf(['dark', 'light']),
  }),
  defaultProps({
    children: null,
    theme: 'light',
  }),
  memo,
));
