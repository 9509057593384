import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { Typography, Container, Icon } from '@material-ui/core';
import { applyTo, pipe, path, isNil, values } from 'ramda';
import { useParams, useLocation } from 'react-router-dom';
import { fromBase64 } from '@vl/js-lib/browser/string';

// aliased
import { WHOAMI } from 'lib/graphql/query';
import { propTypes, defaultProps } from 'lib/react';
import { parseUserEmail } from 'lib/string';
import Breadcrumbs from 'components/Breadcrumbs';
import Link from 'components/Link';
import NestedData from 'components/NestedData';
import CopyToClipboard from 'components/CopyToClipboard';
import Title from 'components/Title';

// local
import styles from './TeamMember.module.scss';

const BREADCRUMBS = {
  team: 'Team Member',
};

// parse a global id
// String -> Node
const fromGlobalId = gid => {
  const decoded = fromBase64(`${ gid }`);
  const [type, id] = decoded.split(':');
  // note: this is more strict than the graphql-relay version
  if (!type || isNil(id) || id === '') throw Error(`Invalid GID "${ gid }"`);
  return { type, id };
};

// eslint-disable-next-line max-statements
export default applyTo(() => {
  const { username } = useParams();
  const { data } = useQuery(WHOAMI);

  const location = useLocation();
  const id = path(['whoami', 'id'], data);

  const isViewer = (id === username);

  const inferred = parseUserEmail(path(['whoami', 'email'], data));

  const firstName = path(['whoami', 'firstName'], data) || inferred[0];
  const name = [
    firstName,
    path(['whoami', 'lastName'], data) || inferred[1],
  ].join(' ');

  const email = path(['whoami', 'email'], data);

  return (
    <Container style={{ marginTop: 25 }}>
      <Title title={ 'Team Member' } />
      <Breadcrumbs path={ location.pathname } data={ BREADCRUMBS } />
      <Typography variant="h1">
        { `${ name } ${ (isViewer ? '(You)' : '') }` }
      </Typography>
      {
        isViewer && (
          <NestedData
            style={{ fontSize: '20px' }}
            data={{
              ID: (
                <span title={ values(fromGlobalId(id)).join(': ') }>
                  { id }
                  <CopyToClipboard
                    disabled={ !id }
                    label={ <Icon>{ 'content_copy' }</Icon> }
                    text={ id }
                    className={ styles.copyButton }
                    title={ 'Copy ID' } />
                </span>
              ),
              Name: name,
              Email: (
                <Link to={ `mailto:${ email }` } title={ `Email ${ firstName }` }>
                  { email }
                </Link>
              ),
            }} />
         
        )
      }
    </Container>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
));
