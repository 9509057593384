import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { applyTo, pipe, mergeDeepRight } from 'ramda';
import BASE_THEME from './base';

const LIGHT_THEME = {
  palette: {
    type: 'light',
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#111111',
      text: '#111111',
    },
    secondary: {
      main: '#3498DB',
    },
  },

  overrides: {
    
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff',
      },
    },
  },
};

export default applyTo(LIGHT_THEME, pipe(
  mergeDeepRight(BASE_THEME),
  createTheme,
  responsiveFontSizes
));
