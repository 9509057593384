/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';
import { Typography, LinearProgress, Container } from '@material-ui/core';
import { applyTo, pipe, assoc, path } from 'ramda';
import { useToasts } from 'react-toast-notifications';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import { useRelayConnection } from 'lib/pagination';
import { SEARCH_PRODUCTS_QUERY } from 'lib/graphql/query';
import { connectionNodes } from 'lib/relay';
import Progress from 'components/Progress';
import FlexCenter from 'components/FlexCenter';
import Error from 'components/Error';
import Pagination from 'components/DataGridPagination';

// local
import { COLUMNS } from './data';
import Filters from '../Filters';

// See if this can be abstracted into a single component.
// seems like props would be:
// query, variables, getConnection, COLUMNS, checkboxSelection

const getConnection = path(['searchUsers']);

export default applyTo(() => { // eslint-disable-line
  const { addToast } = useToasts();
  // NOTE: selectionModel is an array of ids from each row's selected ID property
  const [selectionModel, setSelectionModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});

  // pagination
  const PAGE_SIZE = 5;

  const handleSelectionModelChange = useCallback((newSelectionModel) => {
    setSelectionModel(newSelectionModel);
    console.log('selected:', newSelectionModel);
  }, [setSelectionModel]);
 
  // fixme: query does not exist yet, uncomment the following when it does
  // const {
  //   data,
  //   loading,
  //   error,
  //   nextPage,
  // } = useRelayConnection(SEARCH_USERS_QUERY, {
  //   variables: {
  //     input: {
  //       first: PAGE_SIZE,
  //       after: null,
  //     },
  //   },
  //   getConnection,
  //   onCompleted: handleCompleted,
  // });

  // const handleLoadMoreClick = useCallback(() => {
  //   setIsLoading(true);
  //   nextPage();
  // }, [setIsLoading, nextPage]);

  // const handleQueryChange = useCallback((query) => {
  //   setIsLoading(true);
  //   setFilters(assoc('query', query, filters));
  //   console.log('query:', query);
  // }, [setFilters, filters, setIsLoading]);

  // const handleCompleted = useCallback(() => {
  //   setIsLoading(false);
  // }, [setIsLoading]);

  // const handleExportAll = useCallback(async () => {
  //   try {
  //     addToast('Starting Export', { appearance: 'info', autoDismiss: true });
  //     await downloadAll();
  //     addToast('Completed Export', { appearance: 'success', autoDismiss: true });
  //   } catch (error) {
  //     console.error(error);
  //     addToast(error.message, { appearance: 'error', autoDismiss: true });
  //   }
  // }, []);

  // const handleExportSelected = useCallback(() => {
  //   console.log('export selected');
  // }, []);
  
  // if (loading && !data) return <FlexCenter><Progress /></FlexCenter>;
  // if (error) return <Error error={ error } />;

  const items = connectionNodes(getConnection({}));
  
  return (
    <Container>
      <Typography variant="h1">Users</Typography>
      <div style={{ marginBottom: 50 }}>
        <DataGrid
          rows={ items }
          columns={ COLUMNS }
          onSelectionModelChange={ handleSelectionModelChange }
          selectionModel={ selectionModel }
          // fixme: uncomment when we have data
          // components={{
          //   // TODO: override pagination since we'll never know the total number of pages
          //   // eslint-disable-next-line
          //   Pagination: () => (
          //     <Pagination
          //       onLoadMoreClick={ handleLoadMoreClick }
          //       hasNextPage={ !!nextPage }
          //       disabled={ loading } />
          //   ),
          //   LoadingOverlay: LinearProgress,
          //   // eslint-disable-next-line
          //   Toolbar: () => (
          //     <Filters
          //       showBatchActions={ selectionModel.length !== 0 }
          //       onExportAll={ handleExportAll }
          //       onExportSelected={ handleExportSelected }
          //       onQueryChange={ handleQueryChange } />
          //   ),
          // }}
          loading={ isLoading }
          disableSelectionOnClick={ true }
          autoHeight={ true }
          checkboxSelection={ true } />
      </div>
    </Container>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
));
