import React, { memo, useRef, useCallback, useState } from 'react';
import { applyTo, pipe } from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './index.module.scss';
import { Button } from '@material-ui/core';


export default applyTo(({
  className,
  disabled,
  text,
  label,
  title,
  onCopy,
}) => {
  const textAreaRef = useRef();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleCopy = useCallback(() => {
    textAreaRef.current.select();
    document.execCommand('copy');
    onCopy(textAreaRef.current.value);
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 1000);
  }, [onCopy]);

  return (
    <>
      <Button
        className={ cx(className, styles.copyToClipboard) }
        disabled={ disabled }
        title={ showSuccess ? 'Copied' : title }
        onClick={ handleCopy }
      >
        <span className={ cx(styles.copied, { [styles.hidden]: !showSuccess }) }>
          { 'Copied' }
        </span>
        { label }
      </Button>
      <textarea
        ref={ textAreaRef }
        readOnly={ true }
        className={ styles.textArea }
        value={ text } />
    </>
  );
}, pipe(
  propTypes({
    disabled: PropTypes.bool,
    // the display text / icon
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    // the value to be copied
    text: PropTypes.string,
    title: PropTypes.string,
    onCopy: PropTypes.func,
  }),
  defaultProps({
    label: 'Copy',
    title: 'Copy to Clipboard',
    text: '',
    onCopy: _ => {},
  }),
  memo,
));
