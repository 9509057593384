import React, { useState, useCallback } from 'react';
import { TextField, Button, Container } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { applyTo, pipe } from 'ramda';
import PropTypes from 'prop-types';

// aliased
import { propTypes, defaultProps } from 'lib/react';

export default applyTo(({
  onQueryChange,
  showBatchActions,
  onExportSelected,
  onExportAll,
}) => {
  const [query, setQuery] = useState('');

  const handleQueryChange = useCallback(event => {
    setQuery(event.target.value);
    // TODO: debounce
    onQueryChange(event.target.value);
  }, [setQuery]);

  return (
    <Container disableGutters={ true } style={{ padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(81, 81, 81, 1)' }}>
      <TextField
        placeholder='Search'
        onChange={ handleQueryChange }
        value={ query }
        InputProps={{
          startAdornment: <SearchIcon />,
        }} />
      <div>
        { /* BATCH ACTIONS */ }
        { showBatchActions && (
          <Button variant="text" onClick={ onExportSelected }>Export Selected</Button>
        ) }
        { /* EVERGREEN ACTIONS */ }
        <Button variant="text" onClick={ onExportAll }>Export All</Button>
      </div>
    </Container>
  );
}, pipe(
  propTypes({
    onQueryChange: PropTypes.func,
    onExportAll: PropTypes.func,
    onExportSelected: PropTypes.func,
    showBatchActions: PropTypes.bool,
  }),
  defaultProps({
    onQueryChange: () => {},
    onExportAll: () => {},
    onExportSelected: () => {},
    showBatchActions: false,
  }),
));
