import React from 'react';
// import PropTypes from 'prop-types';
import * as R from 'ramda';

// aliased
import Link from 'components/Link';


export const COLUMNS = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    renderCell: ({ value }) => (
      <Link to={ `${ window.location.pathname }/${ value }` }>
        { R.take(7, value) }
      </Link>
    ),
  },
  { field: 'email', headerName: 'Email', width: 250 },
];
