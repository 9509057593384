import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  append,
  set,
  has,
  lensProp,
  view,
  prop,
  compose,
} from 'ramda';

const defaultGetConnection = _ => {
  throw Error('Pagination getConnection function required');
};

// fixme: this needs to handle changes to query / page size etc
// GraphQLQuery -> Object -> Object
export const useRelayConnection = (query, { // eslint-disable-line max-statements
  // selects the connection type from the resulting query data
  getConnection = defaultGetConnection,
  variables = {},
  // lens pointing to input args
  inputLens = lensProp('input'),
  // called when query completes
  onCompleted = data => {},
  keyArgs = _ => '',
  fetchPolicy = 'cache-first', // cache-and-network
}) => {
  const afterLens = compose(inputLens, lensProp('after'));

  // a change in key results in a full re-initialization of the hook
  const key = JSON.stringify(variables);

  // array of each page's startCursor
  const [cursors, setCursors] = useState([]);
  // current page
  const [page, setPage] = useState(1);
  
  // eslint-disable-next-line complexity
  const handleComplete = useCallback(data => { // eslint-disable-line max-statements
    onCompleted(data);

    const pageInfo = prop('pageInfo', getConnection(data));
    const hasNextPage = prop('hasNextPage', pageInfo);
    const endCursor = prop('endCursor', pageInfo);

    if (!pageInfo) throw Error(`Missing pageInfo in query: ${ query }`);
    if (!has('hasNextPage', pageInfo)) throw Error(`Missing pageInfo.hasNextPage in query: ${ query }`);
    if (!hasNextPage) return;
    if (hasNextPage && !endCursor) throw Error(`Missing pageInfo.endCursor in query: ${ query }`);
    // previously loaded page
    if (page < cursors.length) return;
    if (cursors.includes(endCursor)) {
      throw Error(`Same end cursor received for pages ${ cursors.indexOf(endCursor) } and ${ page }`);
    }
    const newCursors = append(endCursor, cursors);

    setCursors(newCursors);
  }, [onCompleted, cursors, setCursors, getConnection, query, page]);

  const [runQuery, { data, loading, error, fetchMore, refetch }] = useLazyQuery(query, {
    // variables,
    onCompleted: handleComplete,
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
  });

  // reset page and cursors every time the keyArgs changes
  useEffect(() => {
    const cursors = [view(afterLens, variables)];
    setPage(1);
    setCursors(cursors);
    runQuery({ variables });
  }, [key]); // eslint-disable-line

  const handleChange = useCallback(newPage => {
    if (newPage === page) return;
    if (newPage < 1 || newPage > cursors.length) throw Error(`Page ${ newPage } is out of bounds`);
    setPage(newPage);
    const cursor = prop(newPage - 1, cursors);
    const vars = set(afterLens, cursor, variables);

    fetchMore({ variables: vars });
  }, [setPage, page, cursors, fetchMore, key]); // eslint-disable-line

  const handleNext = useCallback(() => handleChange(page + 1), [page, handleChange]);
  const handlePrev = useCallback(() => handleChange(page - 1), [page, handleChange]);

  const pageInfo = prop('pageInfo', getConnection(data));
  if (data && !pageInfo) {
    console.error(`Missing pageInfo for query: ${ query }`);
    throw Error('pageInfo not found. Check your getConnection function');
  }

  return {
    data,
    loading,
    error,
    nextPage: prop('hasNextPage', pageInfo) ? handleNext : null,
    prevPage: prop('hasPreviousPage', pageInfo) ? handlePrev : null,
    page,
    setPage: handleChange,
    totalPages: cursors.length,
    refetch,
  };
};
