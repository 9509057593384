/* note: this file cannot be named "config" due to an unexplainable NODE_PATH + webpack bug */

import * as R from 'ramda';

// local
import packageJson from '../package.json';

/* eslint-disable no-process-env, no-undef */
const {
  REACT_APP_GOOGLE_ANALYTICS_ID: GOOGLE_ANALYTICS_ID = '',
  REACT_APP_INFRA_NODE_ENV: env = 'production',
  CI = false,
  REACT_APP_API_HOST: API_HOST = '',
  REACT_APP_OKTA_URL: OKTA_URL = '',
  REACT_APP_CONSOLE_OKTA_CLIENT_ID: OKTA_CLIENT_ID = '',
  REACT_APP_CONSOLE_OIDC_CLIENT_ID: OIDC_CLIENT_ID = '',
  REACT_APP_SUPPORT_EMAIL: SUPPORT_EMAIL = 'Lst-ValiantLabsEngineering@nike.com',
} = process.env;
/* eslint-enable no-process-env, no-undef */

const CALLBACK_ROUTE = '/callback';

// required env vars
const envOkay = [API_HOST]
  .every(env => env);
if (!envOkay) {
  // eslint-disable-next-line no-console
  console.warn(
    'One or more env vars are missing. Please check your .env file in the project root.'
  );
}


const { version } = packageJson;

// fixme: this should be generated somewhere
const KNOWN_ENVS = [
  {
    name: 'development',
    url: 'https://console.platform.test.vl.nikecloud.com',
  },
  {
    name: 'production',
    url: 'https://console.platform.vl.nikecloud.com',
  },
];

const CONFIG = {
  env,
  GOOGLE_ANALYTICS_ID,
  API_HOST,
  OKTA_URL,
  OKTA_CLIENT_ID,
  OIDC_CLIENT_ID,
  REDIRECT_URI: `${ window.location.origin }${ CALLBACK_ROUTE }`,
  CI,
  SUPPORT_EMAIL,
  ENVS: R.map(cenv => {
    const isActive = (cenv.name === env);
    return {
      ...cenv,
      active: isActive,
      version: (isActive ? `v${ version }` : null),
    };
  }, KNOWN_ENVS),
};

export default CONFIG;
