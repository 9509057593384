import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
// import PropTypes from 'prop-types';
import { Typography, Container } from '@material-ui/core';

// aliased
import Title from 'components/Title';
import { propTypes, defaultProps } from 'lib/react';

// local
// import GlobalId from './components/GlobalId';

export default applyTo(() => {

  return (
    <Container>
      <Title title={ 'Dev' } />
      <hr />
      <Typography variant='h2'>Dev</Typography>
      <hr />
      { /* <GlobalId /> */ }
      <hr />
    </Container>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo
));
