import React, { useContext, useEffect, useMemo, useState } from 'react';
import { UserManager } from 'oidc-client';
import { path, pathOr } from 'ramda';

import { hasCodeInUrl } from 'lib/auth';
import oidcConfig from './OidcConfig';

const AuthContext = React.createContext(null);

// eslint-disable-next-line max-statements
export const useAuthProvider = (userManager) => {

  const [userPayload, setUserPayload] = useState({
    user: null,
    checked: false,
  });

  const setAuthUser = (user) => {
    if (user) user.upmId = pathOr(null, ['profile', 'sub'], user);

    setUserPayload({ user, checked: true });
  };

  useEffect(() => {
    const userCheck = async () => {
      const user = await userManager.getUser();
      setAuthUser(user);
    };

    const userLoaded = (user) => {
      setAuthUser(user);
    };

    const silentRenewError = (error) => {
      console.log('silentRenewError:', error);
    };

    if (userManager) {
      userManager.events?.addUserLoaded(userLoaded);
      userManager.events?.addUserSignedIn(userCheck);
      // userManager.events?.addUserSessionChanged(userCheck);
      // userManager.events?.addAccessTokenExpired(userCheck);
      userManager.events?.addSilentRenewError(silentRenewError);
    }

    return () => {
      if (userManager) {
        userManager.events?.removeUserLoaded(userLoaded);
        userManager.events?.removeUserSignedIn(userCheck);
        // userManager.events?.removeUserSessionChanged(userCheck);
        // userManager.events?.removeAccessTokenExpired(userCheck);
        userManager.events?.removeSilentRenewError(silentRenewError);
      }
    };
  }, [userManager]);

  useEffect(() => {
    async function userCheck() {
      const user = await userManager.getUser();
      setAuthUser(user);
    }

    async function signInUsingCode() {
      // If you need to render more than once per page load,
      // use the sessionStorage checking_code flag, rather than setState to ensure
      // the code only gets parsed once.
      // const checking = sessionStorage.getItem("checking_code");
      // if (!checking) {
      //   sessionStorage.setItem("checking_code", "true");
      //   await userManager.signinRedirectCallback();
      //   sessionStorage.removeItem("checking_code");
      //   history?.replaceState({}, document.title, window.location.pathname);
      // }

      try {
        await userManager.signinRedirectCallback();
      } catch (error) {
        // swallow this error because Nike auth is not the primary auth method for console
        console.error(error);
      }
      // remove the code from the query params, so we do not parse again.
      // const trimmedPath = removeTrailingSlash(window.location.pathname);
      // history?.replaceState({}, document.title, window.location.pathname);
    }

    if (!userManager) {
      return;
    } else if (hasCodeInUrl()) {
      signInUsingCode();
    } else {
      userCheck();
    }
  }, [userManager]);

  const signIn = () => {
    window.sessionStorage.setItem('signin_url', window.location.href);
    return userManager.signinRedirect();
  };
  const signOut = () => {
    window.localStorage.removeItem('id_token');
    return userManager.signoutRedirect();
  };

  const signedIn = Boolean(userPayload.user && !userPayload.user.expired);

  const hasChecked = userPayload.checked;
  const user = userPayload.user;

  const valid = hasChecked && user?.upmId && signedIn;
  const token = valid ? { type: user.token_type, token: user.access_token } : null;

  window.localStorage.setItem('id_token', path(['id_token'], user));

  return {
    token,
    hasChecked,
    userManager,
    user,
    signIn,
    signOut,
    isSignedIn: () => signedIn, // TODO: just pass the value
    needsSignIn: hasChecked && !signedIn,
    flow: userPayload?.user?.profile?.flow,
  };
};

// eslint-disable-next-line
const NikeAuthProvider = ({ children, browser }) => {
  const userManager = useMemo(() => {
    return new UserManager(oidcConfig());
  }, []);

  const auth = useAuthProvider(userManager);
  return <AuthContext.Provider value={ auth }>{ children }</AuthContext.Provider>;
};


export const useNikeAuth = () => {
  return useContext(AuthContext);
};

export default NikeAuthProvider;
