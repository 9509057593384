import React from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { Grid, Button } from '@material-ui/core';

// aliased
import { propTypes, defaultProps } from 'lib/react';

export default applyTo(({
  hasNextPage,
  hasPreviousPage,
  disabled,
  onLoadNextPageClick,
  onLoadPreviousPageClick,
}) => {
  return (
    <Grid container={ true } justifyContent='flex-end' alignItems='flex-end' style={{ padding: 10 }}>
      <div />
      { hasPreviousPage && (
        <Button variant='outlined' disabled={ disabled } onClick={ onLoadPreviousPageClick }>
          Previous Page
        </Button>
      ) }
      { hasNextPage && (
        <Button variant='outlined' disabled={ disabled } onClick={ onLoadNextPageClick }>
          Next Page
        </Button>
      ) }
    </Grid>
  );
}, pipe(
  propTypes({
    hasNextPage: PropTypes.bool,
    disabled: PropTypes.bool,
    onLoadNextPageClick: PropTypes.func,
    onLoadPreviousPageClick: PropTypes.func,
  }),
  defaultProps({
    hasNextPage: true,
    hasPreviousPage: false,
    disabled: false,
    onLoadNextPageClick: () => {},
    onLoadPreviousPageClick: () => {},
  }),
));
