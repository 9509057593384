import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useGATracking = (trackingId) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag || !trackingId) return;
      window.gtag('config', trackingId, { page_path: location.pathname }); // eslint-disable-line camelcase
    });

    return unlisten;
  }, [trackingId, listen]);
};
