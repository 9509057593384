import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './FlexCenter.module.scss';

export const FlexCenter = ({ children, className, ...rest }) => {
  return (
    <div { ...rest } className={ cx(styles.flexCenter, className) }>
      { children }
    </div>
  );
};

FlexCenter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

FlexCenter.defaultProps = {
  children: null,
  className: null,
};

export default memo(FlexCenter);
