/* eslint-disable max-statements, react/no-multi-comp */
import React, { useState, useCallback } from 'react';
// import * as R from 'ramda';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Icon } from '@material-ui/core';

// aliased
import Code from 'components/Code';
import CopyToClipboard from 'components/CopyToClipboard';

// local
import styles from './TokenField.module.scss';


const useBoolean = init => {
  const [bool, set] = useState(init);
  const toggle = useCallback(() => set(val => !val), [set]);
  return [bool, { set, toggle }];
};

export default function TokenField({
  className,
  inputClassName,
  blur: blurInit,
  children: token,
  style,
  title,
  onCopy = _ => {},
  ...rest
}) {

  const [blur, { toggle: toggleBlur }] = useBoolean(blurInit);
  
  return (
    <div className={ cx(className, styles.codeWrap) }>
      <Code
        { ...rest }
        className={ cx(inputClassName, styles.code) }
        style={{
          filter: `blur(${ blur ? 3 : 0 }px)`,
          width: '100%',
          ...style,
        }}
        title={ title }
        inline={ false }
      >{ token }</Code>
      <div className={ styles.actionBar }>
        <Button
          onClick={ toggleBlur }
          title={
            blur
              ? (title ? `Show ${ (title || '') }` : 'Show')
              : (title ? `Hide ${ (title || '') }` : 'Hide')
          }
        >
          { <Icon>{ blur ? 'visibility' : 'visibility_off' }</Icon> }
        </Button>
        <CopyToClipboard
          disabled={ !token }
          label={ <Icon>{ 'content_copy' }</Icon> }
          text={ token }
          className={ 'toolbar-button' }
          title={
            title
              ? `Copy ${ (title || '') }`
              : 'Copy'
          }
          onCopy={ onCopy } />
      </div>
    </div>
  );
}


TokenField.propTypes = {
  blur: PropTypes.bool,
  children: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onCopy: PropTypes.func,
  style: PropTypes.shape({}),
  tab: PropTypes.number,
  title: PropTypes.string,
  token: PropTypes.string,
};

TokenField.defaultProps = {
  onCopy: _ => {},
  tab: 1,
};
