import { curry } from 'ramda';

// mutating
export const propTypes = curry((propTypes, component) => {
  return Object.assign(component, { propTypes });
});

// mutating
export const defaultProps = curry((defaultProps, component) => {
  return Object.assign(component, { defaultProps });
});
